import { Button, Card, Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { StatusContent } from '../components/status-tag/StatusTag.style';

export const OrderList = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 1em;
`;

export const OrderItem = styled(Grid.Column)`
  display: flex;
  margin: 0 !important;
  min-width: 250px;
`;

export const AddFloatButton = styled(Button)`
  position: fixed;
  right: 2em;
  bottom: 2em;
`;

export const FooterContent = styled(Card.Content)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:after {
    display: none !important;
  }
`;
export const QtdCarWashed = styled.div`
  display: flex;
  align-items: center;
`;

export const QtdCarLabel = styled.span`
  margin-left: 0.5rem;
`;
export const OrderActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;


export const OrderCardHeader = styled(Card.Header)`
  display: flex !important;
  justify-content: space-between;
`

export const OrderCardDescription = styled(Card.Description)`
  position: relative;

  ${StatusContent}{
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0px;
  }
`