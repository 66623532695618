import Store from '../../config/store/store.config'
import { IEmployee } from '../../models/employee.model'
import { URLS } from '../../utils/constants/urls'
import { Get, Post } from '../api/Api'
import { IEmployeeBody } from './../../models/employee.model'
import { Delete, Patch } from './../api/Api'

const getSlug = () => {
  return Store.getState().login.userLogged?.context?.slug
}

const EmployeeApi = {
  list: async (): Promise<IEmployee[]> => {
    const response = await Get(URLS.EMPLOYEES(getSlug()))
    if (response.ok) {
      const employees = await response.json()
      return employees
    } else {
      return Promise.reject(await response.json())
    }
  },
  remove: async (id: string): Promise<void> => {
    const response = await Delete(URLS.EMPLOYEE(getSlug(), id))
    if (!response.ok) {
      return Promise.reject(await response.json())
    }
  },
  create: async (employee: IEmployeeBody): Promise<IEmployee> => {
    const response = await Post(URLS.EMPLOYEES(getSlug()), employee)
    if (response.ok) {
      const employee = await response.json()
      return employee
    } else {
      return Promise.reject(await response.json())
    }
  },
  get: async (id: string): Promise<IEmployee> => {
    const response = await Get(URLS.EMPLOYEE(getSlug(), id))
    if (response.ok) {
      const employee = await response.json()
      return employee
    } else {
      return Promise.reject(await response.json())
    }
  },
  update: async (employee: IEmployee): Promise<IEmployee> => {
    const response = await Patch(URLS.EMPLOYEE(getSlug(), employee.id), employee)
    if (response.ok) {
      const employee = await response.json()
      return employee
    } else {
      return Promise.reject(await response.json())
    }
  }
}

export default EmployeeApi
