import { all, call, put, takeEvery } from 'redux-saga/effects';
import VehicleApi from '../../../services/vehicle/Vehicle';
import { IVehicleCategory } from './../../../models/vehicle';
import { changeVehicleCategories } from './actions';
import { SHARED_ACTIONS } from './reducer';

function* getVehicleCategories() {
  try {
    const categories: IVehicleCategory[] = yield call(VehicleApi.categories);
    yield put(changeVehicleCategories(categories));
  } catch (error) {
    console.error(error);
  }
}

export default function* SharedSaga() {
  const getVehicleCategoriesSaga: unknown = yield takeEvery(
    SHARED_ACTIONS.ASYNC_GET_VEHICLE_CATEGORIES,
    getVehicleCategories
  );
  yield all([getVehicleCategoriesSaga]);
}
