import { Card } from "semantic-ui-react";
import styled from "styled-components";

export const DashboardContainer = styled.div`
    height: 100%;
    position: relative;
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-top: -24px;
`

export const DashboardContent = styled.div`
    z-index: 3;
    position: absolute;
    width: 100%;
    padding: 24px;
`;

export const BlueEffect = styled.div`
    width: 100%;
    height: 50%;
    background-color: ${({ theme }) => theme.color.primary};
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 40px 40px;
    z-index: 1;
`

export const RevenuesHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const RevenueTitle = styled.h3`
    font-family: Nunito;
    margin: 0;
    color: ${({ theme }) => theme.actions.primary.contrast};
    font-weight: 300;
    font-size: 1.6em;
`
export const RevenueDateRange = styled.div`
`
export const RangeButton = styled.button<{ active?: boolean }>`
    background-color: transparent;
    color: ${({ theme }) => theme.actions.primary.contrast};
    padding: .5em 1em;
    margin: .2em;
    border: 1px solid ${({ theme }) => theme.actions.primary.contrast};
    border-radius: 10px;

    &.active {
        background-color: ${({ theme }) => theme.actions.primary.contrast};
        color: ${({ theme }) => theme.color.secondary}
    }

`;

export const TotalRevenue = styled.h1`
    font-size: 3em;
    font-weight: 400;
    margin: .2em 0;
    color: ${({ theme }) => theme.actions.primary.contrast};
`

export const RevenueDetailContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`

export const RevenueDetail = styled.div`
    display: flex;
    align-items:center;
    font-family: Nunito;
    width: 50%;
    font-size: 1.4em;
    color: ${({ theme }) => theme.actions.primary.contrast};
    padding: .2em;
    svg {
        margin-right:10px;
    }
`;

export const CarsResumeContent = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 1em;
    margin-top: 1.5em;
    border-radius: 30px 5px 5px 5px;
    box-shadow: 0px 2px 4px #00000040;
`;

export const InfoContent = styled.div`
    display: flex;
`
export const BigInfo = styled.div`
    width: 50%;
    text-align: center;
`;

export const BigInfoTitle = styled.h2`
    font-family: Nunito;
    font-size: 1.6em;
    font-weight: 300;
    margin: 0;
`;
export const BigInfoValue = styled.h1`
    font-family: Nunito;
    font-size: 4em;
    font-weight: 300;
    margin: 0;
`

export const SmallInfo = styled.div`
    width:25%;
    text-align: center;
`
export const SmallInfoTitle = styled.p`
    margin: 0 0 .2em 0;
    font-size: 1.1em;
    font-weight: 300;
`
export const SmallInfoValue = styled.p`
    margin: 0;
    font-size: 1.7em;
    font-weight: 400;
`
export const CarsResumeDivisor = styled.div`
    height: 3px;
    margin-bottom: 1em;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.color.primary};
`

export const ActionsContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
`;

export const ActionButtonContent = styled.div`
    display: flex;
    justify-content: center;
    width: 45%;
    margin: .5em 0;
`

export const ActionButton = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100%) !important;
    height: 100px;
    padding: .7em 0 !important;
    color: ${({ theme }) => theme.color.secondary};
`;

export const ActionLabel = styled.label`
    font-size: 1.2em;
    margin-top: .5em;
`