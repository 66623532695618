import React from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from 'react-pro-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sidebarBg from '../../assets/img/background-left.jpeg';
import { IState } from '../../config/store/MainReducer';
import { ENV } from '../../enviroment/enviroment';
import { changeSideBarToggle } from '../../pages/shared/store/actions';
import { pages } from '../../utils/constants/pages';
import IconClient from '../Icon/IconClient';
import IconEmployee from '../Icon/IconEmployee';
import IconService from '../Icon/IconService';
import { SideBarTitle } from './SideBar.style';

const iconConfig = {
  width: 25,
  height: 25,
  color: '#adadad',
};

const Aside = () => {
  const toggled = useSelector((state: IState) => state.shared.sideBarToggle);
  const dispatch = useDispatch();
  const handleToggle = () => {
    dispatch(changeSideBarToggle(false));
  };
  return (
    <ProSidebar
      image={sidebarBg}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggle}
      style={{ zIndex: 10 }}
    >
      <SidebarHeader>
        <SideBarTitle>Nome da empresa</SideBarTitle>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem icon={<AiOutlineHome size={20} />}>
            <Link to={pages.DASHBOARD} onClick={handleToggle}>
              Início
            </Link>
          </MenuItem>
          <MenuItem icon={<IconClient {...iconConfig} />}>
            <Link to={pages.CLIENTS} onClick={handleToggle}>
              Clientes
            </Link>
          </MenuItem>
          <MenuItem icon={<IconEmployee {...iconConfig} />}>
            <Link to={pages.EMPLOYEES} onClick={handleToggle}>
              Funcionários
            </Link>
          </MenuItem>
          <MenuItem icon={<IconService {...iconConfig} />}>
            <Link to={pages.PRODUCT} onClick={handleToggle}>
              Serviços
            </Link>
          </MenuItem>
          <MenuItem icon={<IconService {...iconConfig} />}>
            <Link to={pages.EXPENSE} onClick={handleToggle}>
              Despesas
            </Link>
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <span
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            Version {ENV.VERSION}
          </span>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
