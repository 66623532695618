import styled from "styled-components";
import { Grid, Button } from 'semantic-ui-react'


export const ClientsListWrapper = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 1em;
`;

export const ClientItem = styled(Grid.Column)`
    display:flex;
    margin:  0 !important;
    min-width: 250px;
`;

export const AddFloatButton = styled(Button)`
    position: fixed;
    right: 2em;
    bottom: 2em;
`

export const QtdCarWashed = styled.div`
    display: flex;
    align-items: center;
`

export const QtdCarLabel = styled.span`
    margin-left: .5rem;
`