import { IVehicle } from './vehicle';

export interface IOrder {
  id: string;
  vehicle: IVehicle;
  products: IOrderProduct[];
  code: string;
  totalPrice: number;
  payment: IOrderPayment;
  contextSlug: string;

  enteredAt?: string;
  finishedAt?: string;

  consumerId?: string;
  consumerName?: string;
  consumerPhoneNumber?: string;

  status: OrderStatus
}

export interface IOrderProduct {
  price: number;
  name: string;
  description: string;
  performedById: string;
}

export interface IOrderPayment {
  method: string;
  receive: number;
  totalPrice: number;
  change: string;
}

export enum OrderStatus {
  WAITING = "WAITING",
  IN_SERVICE = "IN_SERVICE",
  WAITING_PAYMENT = "WAITING_PAYMENT",
  FINISHED = "FINISHED",
};

export const OrderStatusMapped = {
  [OrderStatus.WAITING]: "Aguardando",
  [OrderStatus.IN_SERVICE]: "Em Serviço",
  [OrderStatus.WAITING_PAYMENT]: "Aguardando pagamento",
  [OrderStatus.FINISHED]: "Finalizado",
}


export const OrderNextStatus = {
  [OrderStatus.WAITING]: "Iniciar serviço",
  [OrderStatus.IN_SERVICE]: "Finalizar serviço",
  [OrderStatus.WAITING_PAYMENT]: "Efetuar pagamento",
  [OrderStatus.FINISHED]: null,
}
