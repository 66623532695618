import { ENV } from '../../enviroment/enviroment';

export const URLS = {
  LOGIN: `${ENV.API_BASE}/login/authentication`,
  EMPLOYEES: (slug: string) => `${ENV.API_BASE}/user/${slug}`,
  EMPLOYEE: (slug: string, id: string) => `${ENV.API_BASE}/user/${slug}/${id}`,

  EXPENSES: (slug: string) => `${ENV.API_BASE}/expense/${slug}`,
  EXPENSE: (slug: string, id: string) =>
    `${ENV.API_BASE}/expense/${slug}/${id}`,

  PRODUCTS: (slug: string) => `${ENV.API_BASE}/product/${slug}`,
  PRODUCT: (slug: string, id: string) =>
    `${ENV.API_BASE}/product/${slug}/${id}`,

  CLIENTS: (slug: string) => `${ENV.API_BASE}/customer/${slug}`,
  CLIENT: (slug: string, id: string) =>
    `${ENV.API_BASE}/customer/${slug}/${id}`,

  ORDERS: (slug: string) => `${ENV.API_BASE}/order/${slug}`,
  ORDER: (slug: string, id: string) => `${ENV.API_BASE}/order/${slug}/${id}`,

  VEHICLE_CATEGORY: `${ENV.API_BASE}/vehicle/category`,

  DASHBOARD: (slug: string) => `${ENV.API_BASE}/dashboard/${slug}`,
};
