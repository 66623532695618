interface IProps {
    TriggerComponent: any;
}

const CompletePayment = ({TriggerComponent}: IProps) => {

    return (
        <TriggerComponent/>
    );
}
export default CompletePayment;
