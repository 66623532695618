import { IOrder } from '../../../models/order.model';
import { ORDER_ACTIONS } from './reducer';

export const getOrdersList = () => ({
  type: ORDER_ACTIONS.ASYNC_GET_ORDERS,
});

export const saveOrder = (order: IOrder, navigate?: any) => ({
  type: ORDER_ACTIONS.ASYNC_SAVE_ORDER,
  payload: { order, navigate },
});

export const changeOrders = (orderList: IOrder[]) => ({
  type: ORDER_ACTIONS.CHANGE_ORDERS,
  payload: { orderList },
});

export const clearOrderEdit = () => ({
  type: ORDER_ACTIONS.CLEAR_ORDER_EDIT,
});

export const removeOrder = (id: string) => ({
  type: ORDER_ACTIONS.ASYNC_REMOVE_ORDER,
  payload: { id },
});

export const changeOrderEdit = (orderEdit: IOrder) => ({
  type: ORDER_ACTIONS.CHANGE_ORDER_EDIT,
  payload: { orderEdit },
});

export const getOrder = (id: string) => ({
  type: ORDER_ACTIONS.ASYNC_GET_ORDER,
  payload: { id },
});

export const changeLoading = (loading: boolean) => ({
  type: ORDER_ACTIONS.CHANGE_LOADING,
  payload: { loading },
});

export const changeSaveLoading = (saveLoading: boolean) => ({
  type: ORDER_ACTIONS.CHANGE_SAVE_LOADING,
  payload: { saveLoading },
});
