import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, List } from 'semantic-ui-react';
import Input from '../../../components/Input/Input';
import Loading from '../../../components/Loading/Loading';
import PageHeader from '../../../components/PageTitle/PageTitle';
import { IState } from '../../../config/store/MainReducer';
import { IProduct, IVehiclePrice } from '../../../models/product.model';
import { pages } from '../../../utils/constants/pages';
import { clearProductState, getProduct, saveProduct } from '../store/actions';

const ProductEdit = () => {
  const { loadingProduct, saveLoading, productEdit, categories } = useSelector(
    ({ product, shared }: IState) => ({
      saveLoading: product.saveLoading,
      loadingProduct: product.loading,
      productEdit: product.productEdit,
      categories: shared.vehicleCategories,
    })
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [vehiclesPrice, setVehiclePrice] = useState<IVehiclePrice[]>([]);
  const { id } = useParams();

  useEffect(() => {
    return () => {
      dispatch(clearProductState());
    };
  }, [dispatch]);

  useEffect(() => {
    const newCategories = categories.map(({ id, name }) => {
      let price = 0;
      if (productEdit) {
        price =
          productEdit.vehicles.find(({ categoryId }) => categoryId === id)
            ?.price || 0;
      }
      return {
        categoryId: id,
        price: price,
        name,
      };
    });
    setVehiclePrice(newCategories);
  }, [categories, productEdit]);

  function handlePriceChange(price: number, id: string) {
    const index = vehiclesPrice.findIndex(
      ({ categoryId }) => categoryId === id
    );
    const newVehicle = [...vehiclesPrice];
    newVehicle[index].price = price;
    setVehiclePrice(newVehicle);
  }

  useEffect(() => {
    if (id) {
      dispatch(getProduct(id));
    }
  }, [id, dispatch]);

  return loadingProduct ? (
    <Loading />
  ) : (
    <>
      <PageHeader
        title={`${id ? 'Editar' : 'Criar'} Serviços`}
        backRouter={pages.PRODUCT}
      />
      <Formik
        enableReinitialize
        initialValues={{
          name: productEdit.name,
          description: productEdit.description,
        }}
        onSubmit={(product: Partial<IProduct>) => {
          product = {
            ...product,
            vehicles: vehiclesPrice.map(({ categoryId, price }) => ({
              categoryId,
              price,
            })),
          };
          const productToSave = id ? { ...productEdit, ...product } : product;
          dispatch(saveProduct(productToSave, navigate));
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <label>Nome</label>
              <Input
                placeholder="Nome"
                value={values.name}
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Field>
            <Form.Field>
              <label>Descrição</label>
              <Input
                placeholder="Descrição"
                value={values.description}
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Field>
            <List>
              {vehiclesPrice.map((vehicle) => (
                <List.Item key={vehicle.categoryId}>
                  <Form.Field>
                    <label>{vehicle.name}</label>
                    <NumberFormat
                      name="value"
                      placeholder="Digite o preço"
                      thousandSeparator="."
                      value={vehicle.price}
                      fixedDecimalScale={true}
                      decimalSeparator=","
                      decimalScale={2}
                      prefix="R$ "
                      onValueChange={({ floatValue }) =>
                        handlePriceChange(floatValue ?? 0, vehicle.categoryId)
                      }
                      onBlur={handleBlur}
                    />
                  </Form.Field>
                </List.Item>
              ))}
            </List>
            <Button
              primary
              type="submit"
              disabled={saveLoading}
              loading={saveLoading}
            >
              Salvar
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProductEdit;
