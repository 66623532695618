import { IClientBody } from './../../../models/client.model';
import { IClient } from '../../../models/client.model';
import { CLIENT_ACTIONS } from './reducer';

export const getClientsList = () => ({
  type: CLIENT_ACTIONS.ASYNC_GET_CLIENTS,
});

export const saveClient = (client: IClientBody, navigate: any) => ({
  type: CLIENT_ACTIONS.ASYNC_SAVE_CLIENT,
  payload: { client, navigate }
});

export const changeClients = (clients: IClient[]) => ({
  type: CLIENT_ACTIONS.CHANGE_CLIENTS,
  payload: { clients }
});

export const clearClientState = () => ({
  type: CLIENT_ACTIONS.CLEAR_CLIENT_STATE,
});

export const removeClient = (id: string) => ({
  type: CLIENT_ACTIONS.ASYNC_REMOVE_CLIENT,
  payload: { id }
});


export const changeClientEdit = (clientEdit: IClient | null) => ({
  type: CLIENT_ACTIONS.CHANGE_CLIENT_EDIT,
  payload: { clientEdit }
});


export const getClient = (id: string) => ({
  type: CLIENT_ACTIONS.ASYNC_GET_CLIENT,
  payload: { id }
});

export const changeLoading = (loading: boolean) => ({
  type: CLIENT_ACTIONS.CHANGE_LOADING,
  payload: { loading }
});

export const changeSaveLoading = (saveLoading: boolean) => ({
  type: CLIENT_ACTIONS.CHANGE_SAVE_LOADING,
  payload: { saveLoading }
});
