import { IEmployeeBody } from './../../../models/employee.model';
import { IEmployee } from '../../../models/employee.model';
import { EMPLOYEE_ACTIONS } from './reducer';

export const getEmployeesList = () => ({
  type: EMPLOYEE_ACTIONS.ASYNC_GET_EMPLOYEES,
});

export const saveEmployee = (employee: IEmployeeBody, navigate: any) => ({
  type: EMPLOYEE_ACTIONS.ASYNC_SAVE_EMPLOYEE,
  payload: { employee, navigate }
});

export const changeEmployees = (employees: IEmployee[]) => ({
  type: EMPLOYEE_ACTIONS.CHANGE_EMPLOYEES,
  payload: { employees }
});

export const clearEmployeeState = () => ({
  type: EMPLOYEE_ACTIONS.CLEAR_EMPLOYEE_STATE,
});

export const removeEmployee = (id: string) => ({
  type: EMPLOYEE_ACTIONS.ASYNC_REMOVE_EMPLOYEE,
  payload: { id }
});


export const changeEmployeeEdit = (employeeEdit: IEmployee) => ({
  type: EMPLOYEE_ACTIONS.CHANGE_EMPLOYEE_EDIT,
  payload: { employeeEdit }
});


export const getEmployee = (id: string) => ({
  type: EMPLOYEE_ACTIONS.ASYNC_GET_EMPLOYEE,
  payload: { id }
});

export const changeLoading = (loading: boolean) => ({
  type: EMPLOYEE_ACTIONS.CHANGE_LOADING,
  payload: { loading }
});

export const changeSaveLoading = (saveLoading: boolean) => ({
  type: EMPLOYEE_ACTIONS.CHANGE_SAVE_LOADING,
  payload: { saveLoading }
});
