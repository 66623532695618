import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Input, Select } from 'semantic-ui-react';
import Modal from '../../../../components/Modal/Modal';
import { IState } from '../../../../config/store/MainReducer';
import { IVehicle } from '../../../../models/vehicle';

interface IProps {
  onAddVehicle(vehicle: IVehicle): void;
  onCancelEditVehicle(): void;
  onEditVehicle(vehicleEdited: IVehicle): void;
  onRemoveVehicle(): void;
  vehicle: IVehicle | null;
}

const VehicleEdit = ({
  onAddVehicle,
  vehicle: vehicleToEdit,
  onCancelEditVehicle,
  onRemoveVehicle,
  onEditVehicle,
}: IProps) => {
  const vehicleInitValue = useMemo(
    () => ({
      model: '',
      license_plate: '',
      categoryId: '',
    }),
    []
  );
  const [vehicle, setVehicle] = useState<IVehicle>(vehicleInitValue);

  const [isOpen, setIsOpen] = useState(false);
  const vehicleCategories = useSelector(
    (state: IState) => state.shared.vehicleCategories
  );
  const clearState = useCallback(() => {
    setVehicle(vehicleInitValue);
    setIsOpen(false);
  }, [vehicleInitValue]);

  useEffect(() => {
    if (vehicleToEdit) {
      setIsOpen(true);
      setVehicle({ ...vehicleToEdit, categoryId: vehicleToEdit?.categoryId });
    } else {
      clearState();
    }
  }, [vehicleToEdit, clearState]);
  const categoryOptions = useMemo(() => {
    return vehicleCategories.map((vehicle) => ({
      key: vehicle.id,
      text: vehicle.name,
      value: vehicle.id,
    }));
  }, [vehicleCategories]);

  function saveVehicle() {
    const newVehicle = { ...vehicle };
    newVehicle.category = vehicleCategories.find(
      ({ id }) => id === vehicle.categoryId
    );
    onAddVehicle(newVehicle);
    clearState();
  }

  function editVehicle() {
    onEditVehicle(vehicle);
  }

  function handleCancelModal() {
    setIsOpen(false);
    onCancelEditVehicle();
    setVehicle(vehicleInitValue);
  }

  function handleRemove() {
    onRemoveVehicle();
  }

  return (
    <Modal
      isOpen={isOpen}
      onCancel={handleCancelModal}
      changeOpen={(value) => setIsOpen(value)}
      title={`${vehicleToEdit ? 'Editar' : 'Adicionar'} Veículo`}
      trigger={
        <Button type="button" onClick={() => setIsOpen(true)}>
          Adicionar Veículo
        </Button>
      }
      actions={
        <>
          {vehicleToEdit && (
            <Button color="red" onClick={handleRemove}>
              Deletar
            </Button>
          )}
          <Button basic color="red" onClick={handleCancelModal}>
            Cancelar
          </Button>
          <Button
            content="Salvar"
            onClick={vehicleToEdit ? editVehicle : saveVehicle}
            primary
          />
        </>
      }
    >
      <Form>
        <Form.Field>
          <label>Modelo</label>
          <Input
            placeholder="Modelo"
            value={vehicle.model}
            name="model"
            onChange={({ target }) =>
              setVehicle({ ...vehicle, model: target.value })
            }
          />
        </Form.Field>
        <Form.Field>
          <label>Placa</label>
          <Input
            maxLength="7"
            placeholder="Placa"
            value={vehicle.license_plate}
            name="license_plate"
            onChange={({ target }) =>
              setVehicle({
                ...vehicle,
                license_plate: target.value.toUpperCase(),
              })
            }
          />
        </Form.Field>
        <Form.Field>
          <label>Categoria</label>
          <Select
            value={vehicle.categoryId}
            placeholder="Selecione a categoria"
            options={categoryOptions}
            onChange={(_, { value }) =>
              setVehicle({ ...vehicle, categoryId: value as string })
            }
          />
        </Form.Field>
      </Form>
    </Modal>
  );
};

export default VehicleEdit;
