import React from 'react'
import { theme } from '../../styles/theme'

interface IProps {
  width: number
  height: number
  color?: string
}

function IconClient({ width, height, color = theme.color.secondary }: IProps): JSX.Element {
  return (
    <svg width={width} height={height} viewBox="0 0 50 50" fill="none">
      <g clipPath="url(#clip0_2_74)">
        <path
          d="M25 8.69562C20.8043 8.69562 17.3912 12.1086 17.3912 16.3043C17.3912 20.5001 20.8042 23.9131 25 23.9131C29.1957 23.9131 32.6087 20.5 32.6087 16.3043C32.6087 12.1087 29.1957 8.69562 25 8.69562ZM25 21.7391C22.0043 21.7391 19.5652 19.3 19.5652 16.3043C19.5652 13.3087 22.0043 10.8696 25 10.8696C27.9956 10.8696 30.4347 13.3087 30.4347 16.3043C30.4347 19.3 27.9957 21.7391 25 21.7391Z"
          fill={color}
        />
        <path
          d="M40.2174 17.3913C37.2218 17.3913 34.7827 19.8305 34.7827 22.8261C34.7827 25.8217 37.2218 28.2608 40.2174 28.2608C43.213 28.2608 45.6521 25.8217 45.6521 22.8261C45.6521 19.8305 43.213 17.3913 40.2174 17.3913ZM40.2174 26.0869C38.4195 26.0869 36.9565 24.6239 36.9565 22.8261C36.9565 21.0282 38.4195 19.5652 40.2174 19.5652C42.0153 19.5652 43.4783 21.0282 43.4783 22.8261C43.4783 24.6239 42.0152 26.0869 40.2174 26.0869Z"
          fill={color}
        />
        <path
          d="M9.93697 17.3913C6.93911 17.3913 4.50223 19.8305 4.50223 22.8261C4.50223 25.8217 6.94136 28.2608 9.93697 28.2608C12.9326 28.2608 15.3717 25.8217 15.3717 22.8261C15.3717 19.8305 12.9347 17.3913 9.93697 17.3913ZM9.93697 26.0869C8.13911 26.0869 6.6761 24.6239 6.6761 22.8261C6.6761 21.0282 8.13911 19.5652 9.93697 19.5652C11.7348 19.5652 13.1978 21.0282 13.1978 22.8261C13.1978 24.6239 11.7347 26.0869 9.93697 26.0869Z"
          fill={color}
        />
        <path
          d="M24.9999 26.0869C17.2086 26.0869 10.8695 32.4261 10.8695 40.2174C10.8695 40.8174 11.3565 41.3044 11.9565 41.3044C12.5565 41.3044 13.0435 40.8174 13.0435 40.2174C13.0435 33.6239 18.4065 28.2609 24.9999 28.2609C31.5934 28.2609 36.9564 33.6239 36.9564 40.2174C36.9564 40.8174 37.4434 41.3044 38.0434 41.3044C38.6434 41.3044 39.1304 40.8174 39.1304 40.2174C39.1304 32.4261 32.7913 26.0869 24.9999 26.0869Z"
          fill={color}
        />
        <path
          d="M40.2174 30.4348C38.4283 30.4348 36.6761 30.9239 35.15 31.8479C34.6392 32.1609 34.4739 32.8283 34.7848 33.3414C35.1 33.8544 35.7652 34.0175 36.2783 33.7066C37.463 32.987 38.8239 32.6087 40.2174 32.6087C44.413 32.6087 47.8261 36.0217 47.8261 40.2174C47.8261 40.8174 48.3131 41.3044 48.9131 41.3044C49.5131 41.3044 50.0001 40.8174 50.0001 40.2174C50 34.8239 45.6109 30.4348 40.2174 30.4348Z"
          fill={color}
        />
        <path
          d="M14.8479 31.8456C13.3239 30.9239 11.5718 30.4348 9.7826 30.4348C4.38913 30.4348 0 34.8239 0 40.2174C0 40.8174 0.48699 41.3044 1.08699 41.3044C1.68699 41.3044 2.17398 40.8174 2.17398 40.2174C2.17388 36.0217 5.58699 32.6087 9.7826 32.6087C11.1761 32.6087 12.5369 32.987 13.7196 33.7043C14.2304 34.0152 14.9 33.8522 15.2131 33.3391C15.5261 32.826 15.3608 32.1587 14.8479 31.8456Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2_74">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconClient
