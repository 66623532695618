import styled from "styled-components";

export const PageTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items:center;
    margin-bottom: 1em;
    color: ${({ theme }) => theme.color.secondary};
`

export const IconButton = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items:center;
    padding: .5em;
`