import { IoMdArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { theme } from '../../styles/theme';
import SideBarToggle from '../SideBarToggle/SideBarToggle';
import { Title } from '../Title/Title';
import { IconButton, PageTitleWrapper } from './PageTitle.style';

interface IProps {
  title: string;
  iconContrast?: boolean;
  backRouter?: string;
}
const PageHeader = ({ title, backRouter, iconContrast }: IProps) => {
  const navigate = useNavigate();
  return (
    <PageTitleWrapper>
      {backRouter ? (
        <IconButton onClick={() => navigate(backRouter)}>
          <IoMdArrowBack
            size={25}
            color={
              iconContrast
                ? theme.actions.secondary.contrast
                : theme.actions.secondary.background
            }
          />
        </IconButton>
      ) : (
        <SideBarToggle iconContrast={iconContrast} />
      )}

      <Title fontSize={1.8}>{title}</Title>
      <span style={{ width: 40 }} />
    </PageTitleWrapper>
  );
};

export default PageHeader;
