import { styled } from '../../styles/theme'

export const LoginContainer = styled.article`
  width: 70vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoginContent = styled.div`
  width: 60%;
  max-width: 800px;
`

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 64px 0;
`

export const LoginWrapper = styled.section`
  display: flex;
  width: 100vw;
  &.isMobile {
    ${LoginContainer} {
      width: 100vw;
      text-align: center;
    }
    ${LoginForm} {
      margin: 32px 0;
    }
    ${LoginContent} {
      width: calc(100% - 4rem);
    }
  }
`
