import styled from 'styled-components'

export const BtnToggle = styled.div`
  cursor: pointer;
  width: 35px;
  height: 35px;
  color: #353535;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`
