import { Card } from "semantic-ui-react";
import styled from "styled-components";

export const FooterContent = styled(Card.Content)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &:after {
        display: none !important;
    }
`


export const ClientActions = styled.div`
    display: flex;
    justify-content: flex-end;
`