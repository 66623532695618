import { Outlet } from 'react-router-dom';
import { Container } from '../../components/Container/Container';
import Aside from '../../components/SideBar/SideBar';
import { ContainerWrapper } from './Home.style';

const Home = () => {
  return (
    <ContainerWrapper>
      <Aside />
      <Container>
        <Outlet />
      </Container>
    </ContainerWrapper>
  );
};

export default Home;
