import { FaBars } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import useWindowSize, { ENUMDevices } from '../../hook/windowSize';
import { changeSideBarToggle } from '../../pages/shared/store/actions';
import { BtnToggle } from './SideBartToggle.style';

interface IProps {
  iconContrast?: boolean;
}

const SideBarToggle = ({ iconContrast }: IProps) => {
  const dispatch = useDispatch();
  const { device } = useWindowSize();
  const theme: any = useTheme();

  const handleToggle = (value: boolean) => {
    dispatch(changeSideBarToggle(value));
  };

  return device === ENUMDevices.isMobile ? (
    <BtnToggle onClick={() => handleToggle(true)}>
      <FaBars
        fontSize={24}
        color={
          iconContrast
            ? theme.actions.secondary.contrast
            : theme.actions.secondary.background
        }
      />
    </BtnToggle>
  ) : (
    <span />
  );
};

export default SideBarToggle;
