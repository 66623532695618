import { AlertDialogCancel, AlertDialogAction, AlertDialog, AlertDialogTrigger, AlertDialogPortal } from '@radix-ui/react-alert-dialog'
import { Button } from 'semantic-ui-react'
import { AlertActions, AlertContent, AlertDescription, AlertOverlay, AlertTitle } from './Alert.styled'

interface IProps {
  children: any
  title: string
  description: string
  onConfirm(): void
}

const Alert = ({ children, title, description, onConfirm }: IProps) => (
  <AlertDialog>
    <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
    <AlertDialogPortal>
      <AlertOverlay />
      <AlertContent>
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>{description}</AlertDescription>
        <AlertActions>
          <AlertDialogCancel asChild>
            <Button color="red" basic>
              Cancelar
            </Button>
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button onClick={onConfirm} primary>
              Confirmar
            </Button>
          </AlertDialogAction>
        </AlertActions>
      </AlertContent>
    </AlertDialogPortal>
  </AlertDialog>
)
export default Alert
