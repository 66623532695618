import Store from '../../config/store/store.config';
import { IOrder } from '../../models/order.model';
import { URLS } from '../../utils/constants/urls';
import { Delete, Get, Patch, Post } from '../api/Api';

const getSlug = () => {
  return Store.getState().login.userLogged?.context?.slug;
};

const OrderApi = {
  list: async (): Promise<IOrder[]> => {
    const response = await Get(URLS.ORDERS(getSlug()));
    if (response.ok) {
      const orders = await response.json();
      return orders;
    } else {
      return Promise.reject(await response.json());
    }
  },
  remove: async (id: string): Promise<void> => {
    const response = await Delete(URLS.ORDER(getSlug(), id));
    if (!response.ok) {
      return Promise.reject(await response.json());
    }
  },
  create: async (order: IOrder): Promise<IOrder> => {
    const response = await Post(URLS.ORDERS(getSlug()), order);
    if (response.ok) {
      const order = await response.json();
      return order;
    } else {
      return Promise.reject(await response.json());
    }
  },
  get: async (id: string): Promise<IOrder> => {
    const response = await Get(URLS.ORDER(getSlug(), id));
    if (response.ok) {
      const order = await response.json();
      return order;
    } else {
      return Promise.reject(await response.json());
    }
  },
  update: async (order: IOrder): Promise<IOrder> => {
    const response = await Patch(URLS.ORDER(getSlug(), order.id), order);
    if (response.ok) {
      const order = await response.json();
      return order;
    } else {
      return Promise.reject(await response.json());
    }
  },
};

export default OrderApi;
