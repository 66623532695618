import { useMemo } from 'react';
import { OrderStatus, OrderStatusMapped } from '../../../../models/order.model';
import { StatusContent } from './StatusTag.style';

interface IProps {
  status: OrderStatus;
}

export const OrderStatusColors = {
  [OrderStatus.WAITING]: '#ffb2b2',
  [OrderStatus.IN_SERVICE]: '#ffff00',
  [OrderStatus.WAITING_PAYMENT]: '#b8b9ff',
  [OrderStatus.FINISHED]: '#6aff85',
};

const StatusTag = ({ status }: IProps) => {
  const statusLabel = useMemo(() => OrderStatusMapped[status], [status]);
  return (
    <StatusContent
      style={{
        backgroundColor: OrderStatusColors[status],
      }}
    >
      {statusLabel}
    </StatusContent>
  );
};

export default StatusTag;
