import { IAction } from '../../../models/action.model';
import { IProduct } from '../../../models/product.model';

export enum PRODUCT_ACTIONS {
  CLEAR_STATE = '@PRODUCT_CLEAR_STATE',
  CLEAR_PRODUCT_STATE = '@CLEAR_PRODUCT_STATE',

  CHANGE_LOADING = '@PRODUCT_CHANGE_LOADING',
  CHANGE_SAVE_LOADING = '@PRODUCT_CHANGE_SAVE_LOADING',
  CHANGE_PRODUCTS = '@PRODUCT_CHANGE_PRODUCTS',
  CHANGE_PRODUCT_EDIT = '@CHANGE_PRODUCT_EDIT',
  CHANGE_PRODUCT = '@CHANGE_PRODUCT',

  ASYNC_GET_PRODUCTS = '@PRODUCT_ASYNC_GET_PRODUCTS',
  ASYNC_SAVE_PRODUCT = '@PRODUCT_ASYNC_SAVE_PRODUCT',
  ASYNC_REMOVE_PRODUCT = '@ASYNC_REMOVE_PRODUCT',
  ASYNC_GET_PRODUCT = '@ASYNC_GET_PRODUCT',
}

const INITIAL_STATE = {
  loading: false,
  saveLoading: false,
  productList: [] as IProduct[],
  productEdit: {
    id: '',
    name: '',
    description: '',
    vehicles: [],
  } as IProduct,
};

export type IProductState = typeof INITIAL_STATE;

const ProductReducer = (
  state = INITIAL_STATE,
  action: IAction<PRODUCT_ACTIONS, IProductState>
): IProductState => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_ACTIONS.CHANGE_LOADING:
      return { ...state, loading: payload.loading };

    case PRODUCT_ACTIONS.CHANGE_SAVE_LOADING:
      return { ...state, saveLoading: payload.saveLoading };

    case PRODUCT_ACTIONS.CHANGE_PRODUCTS:
      return { ...state, productList: payload.productList };

    case PRODUCT_ACTIONS.CLEAR_STATE:
      return { ...INITIAL_STATE };

    case PRODUCT_ACTIONS.CHANGE_PRODUCT_EDIT:
      return { ...state, productEdit: payload.productEdit };

    case PRODUCT_ACTIONS.CLEAR_PRODUCT_STATE:
      return { ...INITIAL_STATE };

    case PRODUCT_ACTIONS.CHANGE_PRODUCT:
      return { ...state, productList: payload.productList };

    default:
      return state;
  }
};

export default ProductReducer;
