import NumberFormat from 'react-number-format';

interface IProps {
  value: number | string;
}

const MoneyDisplay = ({ value }: IProps) => {
  return (
    <NumberFormat
      thousandSeparator="."
      value={value}
      fixedDecimalScale={true}
      decimalSeparator=","
      decimalScale={2}
      prefix="R$ "
      displayType={'text'}
    />
  );
};

export default MoneyDisplay;
