import { IAction } from '../../../models/action.model';
import { IOrder, IOrderProduct } from '../../../models/order.model';
export enum ORDER_ACTIONS {
  CLEAR_STATE = '@ORDERS_CLEAR_STATE',
  CLEAR_ORDER_EDIT = '@ORDERS_CLEAR_ORDER_EDIT',

  CHANGE_LOADING = '@ORDERS_CHANGE_LOADING',
  CHANGE_SAVE_LOADING = '@ORDERS_CHANGE_SAVE_LOADING',
  CHANGE_ORDERS = '@ORDERS_CHANGE_ORDERS',
  CHANGE_ORDER_EDIT = '@ORDERS_CHANGE_ORDER_EDIT',

  ASYNC_GET_ORDERS = '@ORDERS_ASYNC_GET_ORDERS',
  ASYNC_SAVE_ORDER = '@ORDERS_ASYNC_SAVE_ORDER',
  ASYNC_GET_ORDER = '@ORDERS_ASYNC_GET_ORDER',
  ASYNC_REMOVE_ORDER = '@ORDERS_ASYNC_REMOVE_ORDER',
  ASYNC_START_SERVICE = '@ORDERS_ASYNC_START_SERVICE',
}

const INITIAL_STATE = {
  loading: false,
  saveLoading: false,
  orderList: [] as IOrder[],
  orderEdit: {
    vehicle: {
      model: '',
      license_plate: '',
      categoryId: undefined,
      category: undefined,
    },
    totalPrice: 0,
    products: [] as IOrderProduct[],
  } as IOrder,
};

export type IOrderState = typeof INITIAL_STATE;

const OrderReducer = (
  state = INITIAL_STATE,
  action: IAction<ORDER_ACTIONS, IOrderState>
): IOrderState => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_ACTIONS.CHANGE_LOADING:
      return { ...state, loading: payload.loading };

    case ORDER_ACTIONS.CHANGE_ORDER_EDIT:
      return { ...state, orderEdit: payload.orderEdit || INITIAL_STATE.orderEdit };

    case ORDER_ACTIONS.CLEAR_ORDER_EDIT:
      return { ...state, orderEdit: INITIAL_STATE.orderEdit };

    case ORDER_ACTIONS.CHANGE_SAVE_LOADING:
      return { ...state, saveLoading: payload.saveLoading };

    case ORDER_ACTIONS.CHANGE_ORDERS:
      return { ...state, orderList: payload.orderList };

    case ORDER_ACTIONS.CLEAR_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default OrderReducer;
