import * as moment from 'moment'
import 'moment/locale/pt-br'
import React from 'react'
import { Provider } from 'react-redux'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import { ThemeProvider } from 'styled-components'
import Store from './config/store/store.config'
import MainRouter from './pages/router'
import './styles/style.scss'
import { theme } from './styles/theme'

moment.locale('pt-br')

function App(): JSX.Element {
  return (
    <Provider store={Store}>
      <ThemeProvider theme={theme}>
        <MainRouter />
      </ThemeProvider>
    </Provider>
  )
}

export default App
