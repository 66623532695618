import { IAction } from '../../../models/action.model';
import { IClient } from '../../../models/client.model';
export enum CLIENT_ACTIONS {
  ASYNC_GET_CLIENTS = '@CLIENTS_ASYNC_GET_CLIENTS',
  CLEAR_STATE = '@CLIENTS_CLEAR_STATE',
  CHANGE_LOADING = '@CLIENTS_CHANGE_LOADING',
  CHANGE_SAVE_LOADING = '@CLIENTS_CHANGE_SAVE_LOADING',
  ASYNC_SAVE_CLIENT = '@CLIENTS_ASYNC_SAVE_CLIENT',
  ASYNC_GET_CLIENT = '@CLIENTS_ASYNC_GET_CLIENT',
  CHANGE_CLIENTS = '@CLIENTS_CHANGE_CLIENTS',
  CHANGE_CLIENT_EDIT = '@CLIENTS_CHANGE_CLIENT_EDIT',
  CLEAR_CLIENT_STATE = '@CLIENTS_CLEAR_CLIENT_STATE',
  ASYNC_REMOVE_CLIENT = '@CLIENTS_ASYNC_REMOVE_CLIENT',
}

const INITIAL_STATE = {
  loading: false,
  saveLoading: false,
  clients: [] as IClient[],
  clientEdit: {
    id: '',
    name: '',
    phoneNumber: '',
    vehicles: [],
  } as IClient,
};

export type IClientState = typeof INITIAL_STATE;

const ClientReducer = (
  state = INITIAL_STATE,
  action: IAction<CLIENT_ACTIONS, IClientState>
): IClientState => {
  const { type, payload } = action;
  switch (type) {
    case CLIENT_ACTIONS.CHANGE_LOADING:
      return { ...state, loading: payload.loading };

    case CLIENT_ACTIONS.CHANGE_CLIENT_EDIT:
      return {
        ...state,
        clientEdit: payload.clientEdit || INITIAL_STATE.clientEdit,
      };

    case CLIENT_ACTIONS.CLEAR_CLIENT_STATE:
      return { ...INITIAL_STATE };

    case CLIENT_ACTIONS.CHANGE_SAVE_LOADING:
      return { ...state, saveLoading: payload.saveLoading };

    case CLIENT_ACTIONS.CHANGE_CLIENTS:
      return { ...state, clients: payload.clients };

    case CLIENT_ACTIONS.CLEAR_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default ClientReducer;
