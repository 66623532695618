import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { IState } from '../../../config/store/MainReducer'
import { IEmployee } from '../../../models/employee.model'
import EmployeeApi from '../../../services/employee/Employee'
import { pages } from '../../../utils/constants/pages'
import { changeEmployeeEdit, changeEmployees, changeLoading, changeSaveLoading, clearEmployeeState } from './actions'
import { EMPLOYEE_ACTIONS } from './reducer'

function* getEmployeeList() {
  try {
    yield put(changeLoading(true))
    const employees: IEmployee[] = yield call(EmployeeApi.list)
    yield put(changeEmployees(employees))
  } catch (error) {
    console.error(error)
  } finally {
    yield put(changeLoading(false))
  }
}

function* getEmployee({ payload }: any) {
  try {
    yield put(changeLoading(true))
    const employee: IEmployee = yield call(EmployeeApi.get, payload.id)
    yield put(changeEmployeeEdit(employee))
  } catch (error) {
    console.error(error)
  } finally {
    yield put(changeLoading(false))
  }
}

function* saveEmployee({ payload }: any) {
  try {
    yield put(changeSaveLoading(true))
    const employees: IEmployee[] = yield select((state: IState) => state.employee.employees)
    if (payload.employee.id) {
      const employee: IEmployee = yield call(EmployeeApi.update, payload.employee)
      const index = employees.findIndex(({ id }) => id === employee.id)
      employees[index] = employee
      yield put(changeEmployees([...employees]))
    } else {
      const employee: IEmployee = yield call(EmployeeApi.create, payload.employee)
      yield put(changeEmployees([...employees, employee]))
    }
    yield put(clearEmployeeState())
    yield call(payload.navigate, pages.EMPLOYEES)
  } catch (error: any) {
    alert(error.message)
    console.error(error)
  } finally {
    yield put(changeSaveLoading(false))
  }
}

function* removeEmployee({ payload }: any) {
  try {
    yield put(changeLoading(true))
    const employees: IEmployee[] = yield select((state: IState) => state.employee.employees)
    yield call(EmployeeApi.remove, payload.id)
    const index = employees.findIndex(({ id }) => id === payload.id)
    if (index !== -1) {
      employees.splice(index, 1)
    }
    yield put(changeEmployees([...employees]))
  } catch (error: any) {
    alert(error.message)
    console.error(error)
  } finally {
    yield put(changeLoading(false))
  }
}

export default function* EmployeeSaga() {
  const getEmployeeListSaga: unknown = yield takeEvery(EMPLOYEE_ACTIONS.ASYNC_GET_EMPLOYEES, getEmployeeList)

  const saveEmployeeSaga: unknown = yield takeEvery(EMPLOYEE_ACTIONS.ASYNC_SAVE_EMPLOYEE, saveEmployee)

  const getEmployeeSaga: unknown = yield takeEvery(EMPLOYEE_ACTIONS.ASYNC_GET_EMPLOYEE, getEmployee)

  const removeEmployeeSaga: unknown = yield takeEvery(EMPLOYEE_ACTIONS.ASYNC_REMOVE_EMPLOYEE, removeEmployee)

  yield all([getEmployeeListSaga, saveEmployeeSaga, getEmployeeSaga, removeEmployeeSaga])
}
