import { useMemo } from 'react';
import { AiOutlineCar } from 'react-icons/ai';
import { Card } from 'semantic-ui-react';
import useWindowSize, { ENUMDevices } from '../../../../hook/windowSize';
import { IVehicle } from '../../../../models/vehicle';
import { styled } from '../../../../styles/theme';

interface IProps {
  vehicle: IVehicle;
}

export const TitleCar = styled.p`
  display: flex;
  align-items: center;
`;

const VehicleCard = ({ vehicle }: IProps) => {
  const { device } = useWindowSize();
  const cardWidth = useMemo(() => {
    return device === ENUMDevices.isMobile ? '100%' : 'unset';
  }, [device]);
  return (
    <Card style={{ width: cardWidth }}>
      <Card.Content>
        <Card.Header>{vehicle.model}</Card.Header>
        <Card.Meta>
          <TitleCar>
            <AiOutlineCar width={15} height={15} /> {vehicle.category?.name}
          </TitleCar>
        </Card.Meta>
        <Card.Description>Placa: {vehicle.license_plate}</Card.Description>
      </Card.Content>
    </Card>
  );
};

export default VehicleCard;
