import { useCallback, useEffect, useMemo } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { GrAdd } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'semantic-ui-react';
import Alert from '../../../components/Alert/Alert';
import EmptyContent from '../../../components/EmptyContent/EmptyContent';
import MoneyDisplay from '../../../components/MoneyDisplay/MoneyDisplay';
import PageHeader from '../../../components/PageTitle/PageTitle';
import { IState } from '../../../config/store/MainReducer';
import useWindowSize, { ENUMDevices } from '../../../hook/windowSize';
import {
  IOrder,
  OrderNextStatus,
  OrderStatus,
} from '../../../models/order.model';
import { pages } from '../../../utils/constants/pages';
import ChooseEmployeeToService from '../components/choose-employee/ChooseEmployee';
import StatusTag from '../components/status-tag/StatusTag';
import { getOrdersList, removeOrder, saveOrder } from '../store/actions';
import {
  AddFloatButton,
  FooterContent,
  OrderActions,
  OrderCardDescription,
  OrderCardHeader,
  OrderItem,
  OrderList,
} from './OrderList.style';
import CompleteService from "../components/complete-service/CompleteService";
import CompletePayment from "../components/complete-payment/CompletePayment";

const OrderListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orders: IOrder[] = useSelector(
    (state: IState) => state.order.orderList
  );
  console.log(orders);
  const { device } = useWindowSize();

  useEffect(() => {
    dispatch(getOrdersList());
  }, [dispatch]);

  const cardWidth = useMemo(() => {
    return device === ENUMDevices.isMobile ? '100%' : 'unset';
  }, [device]);

  const goToCreate = useCallback(() => {
    navigate(pages.ORDER_CREATE);
  }, [navigate]);

  const goToEditPage = useCallback(
    (id: string) => {
      navigate(pages.ORDER_EDIT(id));
    },
    [navigate]
  );

  function handleDeleteOrder(id: string) {
    dispatch(removeOrder(id));
  }

  function handleChooseEmployee(order: IOrder, employeeId: string) {
    const newOrder = { ...order };
    newOrder.products = newOrder.products.map((product) => ({
      ...product,
      performedById: employeeId,
    }));
    newOrder.status = OrderStatus.IN_SERVICE;
    dispatch(saveOrder(newOrder));
  }

  function handleCompletePayment(order:IOrder){
    const newOrder = { ...order };
    newOrder.status = OrderStatus.FINISHED;
    if(newOrder.consumerPhoneNumber){
      const message = `Olá ${order.consumerName}, seu carro ${order.vehicle.model} já esta pronto}`;
      const url = `https://api.whatsapp.com/send?phone=${order.consumerPhoneNumber}&text=${message}
      `;
      window.open(url, '_blank')
    }
    dispatch(saveOrder(newOrder));
  }

  function handleCompleteService(order:IOrder){
      const newOrder = { ...order };
      newOrder.status = OrderStatus.WAITING_PAYMENT;
      dispatch(saveOrder(newOrder));
  }

  function renderButton(order: IOrder){
    switch(order.status){
      case OrderStatus.WAITING:
        return <ChooseEmployeeToService
            TriggerComponent={() => (
              <Button primary>
                {OrderNextStatus[order.status]}
              </Button>
            )} onChoose={(employeeId) => handleChooseEmployee(order, employeeId)} />
      case OrderStatus.IN_SERVICE:
        return <CompleteService
            TriggerComponent={() => (
                <Button primary onClick={() => handleCompleteService(order)}>
                  {OrderNextStatus[order.status]}
                </Button>
            )}/>;
      case OrderStatus.WAITING_PAYMENT:
        return <CompletePayment
            TriggerComponent={() => (
                <Button primary onClick={() => handleCompletePayment(order)}>
                  {OrderNextStatus[order.status]}
                </Button>
            )}/>;
      default:
        return <span/>
    }
  }
  return (
    <>
      <PageHeader title="Pátio" backRouter={pages.DASHBOARD} />
      <OrderList columns="equal">
        {orders.length > 0 ? (
          orders.map((order) => (
            <OrderItem key={order.id}>
              <Card style={{ width: cardWidth }}>
                <Card.Content>
                  <OrderCardHeader>
                    <span>{order.vehicle?.license_plate}</span>
                    <MoneyDisplay value={order.totalPrice}/>
                  </OrderCardHeader>

                  <OrderCardDescription>
                    {order.vehicle?.model && (
                      <span>
                        Modelo: <strong>{order.vehicle?.model}</strong>
                        <br />
                      </span>
                    )}
                    {order.consumerName && (
                      <span>
                        Cliente: <strong>{order.consumerName}</strong>
                        <br />
                      </span>
                    )}
                    {order.consumerPhoneNumber && (
                      <span>
                        Celular: <strong>{order.consumerPhoneNumber}</strong>
                        <br />
                      </span>
                    )}
                    <StatusTag status={order.status} />
                  </OrderCardDescription>
                </Card.Content>
                <FooterContent>
                  {renderButton(order)}
                  <OrderActions>
                    <Button icon onClick={() => goToEditPage(order.id)} primary>
                      <AiOutlineEdit fontSize={20} />
                    </Button>
                    <Alert
                      title="Remover do pátio"
                      description={`Tem certeza que deseja remover do pátio o carro ${order.vehicle?.license_plate} ?`}
                      onConfirm={() => handleDeleteOrder(order.id)}
                    >
                      <Button color="red" icon basic>
                        <AiOutlineDelete fontSize={20} />
                      </Button>
                    </Alert>
                  </OrderActions>
                </FooterContent>
              </Card>
            </OrderItem>
          ))
        ) : (
          <EmptyContent message="Sem carros no pátio atual"/>
        )}
      </OrderList>
      <AddFloatButton icon circular onClick={goToCreate}>
        <GrAdd fontSize={20} />
      </AddFloatButton>
    </>
  );
};

export default OrderListPage;
