import styled, { keyframes } from "styled-components";
import {
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogTitle,
    AlertDialogDescription
} from '@radix-ui/react-alert-dialog';

const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
});

const contentShow = keyframes({
    '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
    '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

export const AlertOverlay = styled(AlertDialogOverlay)`
    background-color: #0000006e;
    position: fixed;
    inset: 0;
    @media (prefers-reduced-motion: no-preference){
        animation: ${overlayShow} 150ms cubic - bezier(0.16, 1, 0.3, 1),
    };
`;

export const AlertContent = styled(AlertDialogContent)`
    background-color: white;
    border-radius: 6px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 500px;
    max-height: 85vh;
    padding: 25px;
    @media (prefers-reduced-motion: no-preference){
        anim: ${contentShow} 150ms cubic - bezier(0.16, 1, 0.3, 1),
    };
    &:focus { 
        outline: none;
     };
`;


export const AlertTitle = styled(AlertDialogTitle)`
    margin: 0;
    color: ${({ theme }) => theme.color.primary};
    font-size: 1.5em;
    font-weight: bold;
`;

export const AlertDescription = styled(AlertDialogDescription)`
    margin-top: 20px;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.color.dark_grey};
    font-size: 1.2em;
    line-height: 1.2em;
`;

export const AlertActions = styled.div`
    display: flex;
    justify-content: flex-end;
`;

