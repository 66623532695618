import baseStyled, { ThemedStyledInterface } from 'styled-components';

export const theme = {
  actions: {
    primary: {
      background: '#2987CD',
      contrast: '#fff',
    },
    secondary: {
      background: '#455A64',
      contrast: '#ECEFF1',
    },
    tertiary: {
      background: '#D9DCE1',
      contrast: '#A9AEB4',
    },
  },
  color: {
    primary: '#2987CD',
    secondary: '#455A64',
    background: '#F9F9FB',
    dark_grey: '#949EA8',
    grey: '#A9AEB4',
    ligth_grey: '#CACDD0',
    green: '#1BE38B',
    white: '#ffffff',
    error: '#FF4081',
  },
  box_shadow: '0px 2px 3px rgba(0, 0, 0, 0.108696);',
};

export type Theme = typeof theme;
export type ITheme = ThemedStyledInterface<Theme>;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
