import React from 'react';
import { BackgroundImage, BackgroundOpacity, Divisor, LeftBannerContent, MentionAuthor, MentionMessage } from './LeftBannerStyle';

function LeftBanner(): JSX.Element {
  return (
    <LeftBannerContent>
      <BackgroundImage>
      </BackgroundImage>
      <BackgroundOpacity>
      </BackgroundOpacity>
      <MentionMessage>
        &quot;Algum slogan Legal.&quot; 
      </MentionMessage>
      <Divisor />
      <MentionAuthor className="mention-author">
        Lava Jato
      </MentionAuthor>
    </LeftBannerContent>
  );
}

export default LeftBanner;
