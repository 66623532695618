import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'semantic-ui-react'
import LeftBanner from '../../components/LeftBanner/LeftBanner'
import { SubTitle } from '../../components/Subtitle/Subtitle'
import { Title } from '../../components/Title/Title'
import { IState } from '../../config/store/MainReducer'
import useWindowSize, { ENUMDevices } from '../../hook/windowSize'
import { theme } from '../../styles/theme'
import { LoginContainer, LoginContent, LoginForm, LoginWrapper } from './LoginStyle'
import { doLogin } from './store/actions'
import { Formik } from 'formik'
import Input from '../../components/Input/Input'
import { ILoginCredentials } from './store/reducer'
import { useNavigate } from 'react-router-dom'
import { validEmail } from '../../utils/functions'

export interface IInputChange {
  id: string
  value: any
}

export default function Login(): JSX.Element {
  const { loading, loginError } = useSelector(({ login }: IState) => ({
    loginError: login.loginError,
    loading: login.loading
  }))

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { device } = useWindowSize()

  function handlerLogin(credentials: ILoginCredentials) {
    dispatch(doLogin(credentials, navigate))
  }

  return (
    <LoginWrapper className={device}>
      {device === ENUMDevices.isDesktop && <LeftBanner />}
      <LoginContainer>
        <LoginContent>
          <Title fontSize={2}>Bem vindo ao Lava Jato</Title>
          {loginError ? (
            <SubTitle fontSize={1.3} color={theme.actions.secondary.background}>
              {loginError}
            </SubTitle>
          ) : (
            <SubTitle fontSize={1.3}>Entre com seus dados abaixo</SubTitle>
          )}

          <Formik
            initialValues={{ email: '', password: '' }}
            validate={values => {
              let errors: any = {}
              errors = { ...validEmail(values.email), ...errors }
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              handlerLogin(values)
              setSubmitting(false)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit
              /* and other goodies */
            }) => (
              <LoginForm onSubmit={handleSubmit}>
                <Input
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={touched && errors.email}
                  value={values.email}
                />
                <Input
                  error={Boolean(errors.password && touched.password)}
                  placeholder="Senha"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <Button style={{ marginTop: '1em' }} type="submit" primary disabled={loading || Boolean(Object.keys(errors).length)}>
                  {loading ? 'CARREGANDO...' : 'ACESSAR'}
                </Button>
              </LoginForm>
            )}
          </Formik>
        </LoginContent>
      </LoginContainer>
    </LoginWrapper>
  )
}
