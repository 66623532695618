import styled from 'styled-components'

export const SideBarTitle = styled.h3`
  padding: 24px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
`
