import { IVehicleCategory } from '../../models/vehicle'
import { URLS } from '../../utils/constants/urls'
import { Get } from '../api/Api'

const VehicleApi = {

    categories: async (): Promise<IVehicleCategory[]> => {
        const response = await Get(URLS.VEHICLE_CATEGORY)
        if (response.ok) {
            const expenses = await response.json()
            return expenses
        } else {
            return Promise.reject(await response.json())
        }
    },
}

export default VehicleApi;
