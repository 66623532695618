import { useCallback } from 'react';
import { GrAdd } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import { FloatButton } from '../../../components/FloatButton/FloatButton';
import PageHeader from '../../../components/PageTitle/PageTitle';
import { pages } from '../../../utils/constants/pages';
import ClientsList from '../components/clients-list/ClientsList';

const ClientListPage = () => {
  const navigate = useNavigate();

  const goToCreate = useCallback(() => {
    navigate(pages.CLIENT_CREATE);
  }, [navigate]);

  return (
    <>
      <PageHeader title="Clientes" />
      <ClientsList />
      <FloatButton icon circular onClick={goToCreate}>
        <GrAdd fontSize={20} />
      </FloatButton>
    </>
  );
};

export default ClientListPage;
