import { useCallback, useMemo } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'semantic-ui-react';
import Alert from '../../../../components/Alert/Alert';
import IconClient from '../../../../components/Icon/IconClient';
import useWindowSize, { ENUMDevices } from '../../../../hook/windowSize';
import { IClient } from '../../../../models/client.model';
import { pages } from '../../../../utils/constants/pages';
import { removeClient } from '../../store/actions';
import { ClientActions, FooterContent } from './ClientCard.style';

interface IProps {
  client: IClient;
  noAction?: boolean;
}
const ClientCard = ({ client, noAction }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { device } = useWindowSize();

  const cardWidth = useMemo(() => {
    return device === ENUMDevices.isMobile ? '100%' : 'unset';
  }, [device]);

  const goToEditPage = useCallback(
    (id: string) => {
      navigate(pages.CLIENT_EDIT(id));
    },
    [navigate]
  );

  function handleDeleteClient(id: string) {
    dispatch(removeClient(id));
  }

  return (
    <Card style={{ width: cardWidth }}>
      <Card.Content>
        <Card.Header>{client.name}</Card.Header>
        <Card.Meta>
          <span className="date">
            <IconClient width={15} height={15} /> Cliente
          </span>
        </Card.Meta>
        <Card.Description>Telefone: {client.phoneNumber}</Card.Description>
      </Card.Content>
      {!noAction && (
        <FooterContent>
          <ClientActions>
            <Button icon onClick={() => goToEditPage(client.id)} primary>
              <AiOutlineEdit fontSize={20} />
            </Button>
            <Alert
              title="Excluir cliente"
              description={`Tem certeza que deseja excluir o cliente ${client.name} ?`}
              onConfirm={() => handleDeleteClient(client.id)}
            >
              <Button color="red" icon basic>
                <AiOutlineDelete fontSize={20} />
              </Button>
            </Alert>
          </ClientActions>
        </FooterContent>
      )}
    </Card>
  );
};

export default ClientCard;
