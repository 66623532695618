import { useCallback, useEffect, useMemo } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { GrAdd } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'semantic-ui-react';
import Alert from '../../../components/Alert/Alert';
import EmptyContent from '../../../components/EmptyContent/EmptyContent';
import IconEmployee from '../../../components/Icon/IconEmployee';
import PageHeader from '../../../components/PageTitle/PageTitle';
import { IState } from '../../../config/store/MainReducer';
import useWindowSize, { ENUMDevices } from '../../../hook/windowSize';
import { IEmployee } from '../../../models/employee.model';
import { pages } from '../../../utils/constants/pages';
import { getEmployeesList, removeEmployee } from '../store/actions';
import {
  AddFloatButton,
  EmployeeActions,
  EmployeeItem,
  EmployeeList,
  FooterContent,
} from './EmployeesList.style';

const EmployeeListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const employees: IEmployee[] = useSelector(
    (state: IState) => state.employee.employees
  );
  const { device } = useWindowSize();

  useEffect(() => {
    dispatch(getEmployeesList());
  }, [dispatch]);

  const cardWidth = useMemo(() => {
    return device === ENUMDevices.isMobile ? '100%' : 'unset';
  }, [device]);

  const goToCreate = useCallback(() => {
    navigate(pages.EMPLOYEE_CREATE);
  }, [navigate]);

  const goToEditPage = useCallback(
    (id: string) => {
      navigate(pages.EMPLOYEE_EDIT(id));
    },
    [navigate]
  );

  function handleDeleteEmployee(id: string) {
    dispatch(removeEmployee(id));
  }
  return (
    <>
      <PageHeader title="Funcionários" />
      <EmployeeList columns="equal">
        {employees.length > 0 ? (
          employees.map((employee) => (
            <EmployeeItem key={employee.id}>
              <Card style={{ width: cardWidth }}>
                <Card.Content>
                  <Card.Header>{employee.name}</Card.Header>
                  <Card.Meta>
                    <span className="date">
                      <IconEmployee width={15} height={15} /> Funcionário
                    </span>
                  </Card.Meta>
                  <Card.Description>Email: {employee.email}</Card.Description>
                </Card.Content>
                <FooterContent>
                  {/* <QtdCarWashed>
                  <IconWashCar width={20} height={20} />
                  <QtdCarLabel>33 Lavagens</QtdCarLabel>
                </QtdCarWashed> */}
                  <EmployeeActions>
                    <Button
                      icon
                      onClick={() => goToEditPage(employee.id)}
                      primary
                    >
                      <AiOutlineEdit fontSize={20} />
                    </Button>
                    <Alert
                      title="Excluir funcionário"
                      description={`Tem certeza que deseja excluir o funcionário ${employee.name} ?`}
                      onConfirm={() => handleDeleteEmployee(employee.id)}
                    >
                      <Button color="red" icon basic>
                        <AiOutlineDelete fontSize={20} />
                      </Button>
                    </Alert>
                  </EmployeeActions>
                </FooterContent>
              </Card>
            </EmployeeItem>
          ))
        ) : (
          <EmptyContent message="Nenhum funcionário cadastrado"></EmptyContent>
        )}
      </EmployeeList>
      <AddFloatButton icon circular onClick={goToCreate}>
        <GrAdd fontSize={20} />
      </AddFloatButton>
    </>
  );
};

export default EmployeeListPage;
