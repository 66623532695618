import { IAction } from '../../../models/action.model';
import { IDashboardStatistic } from '../../../models/dashboard.model';

export enum DASHBOARD_ACTIONS {
  CLEAR_DASHBOARD_STATE = '@CLEAR_DASHBOARD_STATE',

  CHANGE_LOADING = '@DASHBOARD_CHANGE_LOADING',
  CHANGE_DASHBOARD = '@DASHBOARD_CHANGE_DASHBOARD',

  ASYNC_GET_DASHBOARD = '@DASHBOARD_ASYNC_GET_DASHBOARD',
}

const INITIAL_STATE = {
  loading: false,
  dashboardStatistic: {
    payment: {
      total: 0,
      totalPaid: 0,
      totalPending: 0,

      totalCredit: 0,
      totalDebit: 0,
      totalPix: 0,
      totalCash: 0,
    },
    order: {
      total: 0,
      totalPending: 0,
    },
    vehicleCategoryList: [],
    expense: {
      total: 0,
    },
  } as IDashboardStatistic,
};

export type IDashboardState = typeof INITIAL_STATE;

const DashboardReducer = (
  state = INITIAL_STATE,
  action: IAction<DASHBOARD_ACTIONS, IDashboardState>
): IDashboardState => {
  const { type, payload } = action;
  switch (type) {
    case DASHBOARD_ACTIONS.CHANGE_LOADING:
      return { ...state, loading: payload.loading };

    case DASHBOARD_ACTIONS.CHANGE_DASHBOARD:
      return { ...state, dashboardStatistic: payload.dashboardStatistic };

    case DASHBOARD_ACTIONS.CLEAR_DASHBOARD_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default DashboardReducer;
