import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { getEmployeesList } from '../employee/store/actions';

const OrderPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmployeesList());
  }, [dispatch]);
  return <Outlet />;
};

export default OrderPage;
