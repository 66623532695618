import { IExpense } from '../../../models/expense.model';
import { EXPENSE_ACTIONS } from './reducer';

/**
 * @param startDate YYYY-MM-DD
 * @param endDate YYYY-MM-DD
 */
export const getExpenseList = (startDate?: string, endDate?: string) => ({
  type: EXPENSE_ACTIONS.ASYNC_GET_EXPENSES,
  payload: {
    startDate,
    endDate,
  },
});

export const saveExpense = (expense: Partial<IExpense>, navigate: any) => ({
  type: EXPENSE_ACTIONS.ASYNC_SAVE_EXPENSE,
  payload: { expense, navigate },
});

export const changeExpenses = (expenseList: IExpense[]) => ({
  type: EXPENSE_ACTIONS.CHANGE_EXPENSES,
  payload: { expenseList },
});

export const changeLoading = (loading: boolean) => ({
  type: EXPENSE_ACTIONS.CHANGE_LOADING,
  payload: { loading },
});

export const changeSaveLoading = (saveLoading: boolean) => ({
  type: EXPENSE_ACTIONS.CHANGE_LOADING,
  payload: { saveLoading },
});

export const clearExpenseState = () => ({
  type: EXPENSE_ACTIONS.CLEAR_EXPENSE_STATE,
});

export const removeExpense = (id: string) => ({
  type: EXPENSE_ACTIONS.ASYNC_REMOVE_EXPENSE,
  payload: { id },
});

export const changeExpenseEdit = (expenseEdit: IExpense) => ({
  type: EXPENSE_ACTIONS.CHANGE_EXPENSE_EDIT,
  payload: { expenseEdit },
});

export const getExpense = (id: string) => ({
  type: EXPENSE_ACTIONS.ASYNC_GET_EXPENSE,
  payload: { id },
});
