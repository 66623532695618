import { IDashboardStatistic } from '../../../models/dashboard.model';
import { DASHBOARD_ACTIONS } from './reducer';

export const changeDashboardStatistic = (
  dashboardStatistic: IDashboardStatistic
) => ({
  type: DASHBOARD_ACTIONS.CHANGE_DASHBOARD,
  payload: { dashboardStatistic },
});

export const changeLoading = (loading: boolean) => ({
  type: DASHBOARD_ACTIONS.CHANGE_LOADING,
  payload: { loading },
});

export const clearDashboardState = () => ({
  type: DASHBOARD_ACTIONS.CLEAR_DASHBOARD_STATE,
});

/**
 * @param startDate YYYY-MM-DD
 * @param endDate YYYY-MM-DD
 */
export const getDashboardStatistic = (
  startDate?: string,
  endDate?: string
) => ({
  type: DASHBOARD_ACTIONS.ASYNC_GET_DASHBOARD,
  payload: {
    startDate,
    endDate,
  },
});
