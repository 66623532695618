import { Formik } from 'formik';
import moment from 'moment';
import { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Form } from 'semantic-ui-react';
import Input from '../../../components/Input/Input';
import Loading from '../../../components/Loading/Loading';
import PageHeader from '../../../components/PageTitle/PageTitle';
import { IState } from '../../../config/store/MainReducer';
import { IExpense } from '../../../models/expense.model';
import { pages } from '../../../utils/constants/pages';
import { clearExpenseState, getExpense, saveExpense } from '../store/actions';

const ExpenseEdit = () => {
  const { loadingExpense, saveLoading, expenseEdit } = useSelector(
    ({ expense }: IState) => ({
      saveLoading: expense.saveLoading,
      loadingExpense: expense.loading,
      expenseEdit: expense.expenseEdit,
    })
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getExpense(id));
    }
    return () => {
      dispatch(clearExpenseState());
    };
  }, [id, dispatch]);

  return loadingExpense ? (
    <Loading />
  ) : (
    <>
      <PageHeader
        title={`${id ? 'Editar' : 'Criar'} Despesa`}
        backRouter={pages.EXPENSE}
      />
      <Formik
        enableReinitialize
        initialValues={{
          name: expenseEdit.name,
          description: expenseEdit.description,
          value: expenseEdit.value,
          paymentDate: expenseEdit.paymentDate,
        }}
        onSubmit={(expense: Partial<IExpense>) => {
          let expenseToSave = id ? { ...expenseEdit, ...expense } : expense;
          dispatch(saveExpense(expenseToSave, navigate));
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <label>Nome</label>
              <Input
                placeholder="Nome"
                value={values.name}
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Field>
            <Form.Field>
              <label>Descrição</label>
              <Input
                placeholder="Descrição"
                value={values.description}
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Field>
            <Form.Field>
              <label>Valor</label>
              <NumberFormat
                name="value"
                placeholder="Please enter a number"
                thousandSeparator="."
                value={values.value}
                fixedDecimalScale={true}
                decimalSeparator=","
                decimalScale={2}
                prefix="R$ "
                onValueChange={(values: any) => {
                  const { floatValue } = values;
                  setFieldValue('value', floatValue);
                }}
                onBlur={handleBlur}
              />
            </Form.Field>
            <Form.Field>
              <label>Data de pagamento</label>
              <SemanticDatepicker
                value={moment(values.paymentDate).toDate()}
                locale="pt-BR"
                format="DD/MM/YYYY"
                onChange={(event, data) => {
                  setFieldValue(
                    'paymentDate',
                    moment(data.value as Date).format('YYYY-MM-DD')
                  );
                }}
              />
            </Form.Field>
            <Button primary type="submit" disabled={saveLoading}>
              {saveLoading ? 'Salvando...' : 'Salvar'}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ExpenseEdit;
