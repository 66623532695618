import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, List } from 'semantic-ui-react';
import Input from '../../../components/Input/Input';
import Loading from '../../../components/Loading/Loading';
import PageHeader from '../../../components/PageTitle/PageTitle';
import { Title } from '../../../components/Title/Title';
import { IState } from '../../../config/store/MainReducer';
import { IClientBody } from '../../../models/client.model';
import { IVehicle } from '../../../models/vehicle';
import { pages } from '../../../utils/constants/pages';
import { clearClientState, getClient, saveClient } from '../store/actions';
import VehicleCard from './components/VehicleCard';
import VehicleEdit from './components/VehicleEdit';

const ClientEdit = () => {
  const { loadingClient, saveLoading, clientEdit } = useSelector(
    ({ client }: IState) => ({
      saveLoading: client.saveLoading,
      loadingClient: client.loading,
      clientEdit: client.clientEdit,
    })
  );

  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const [indexVehicleToEdit, setIndexVehicleToEdit] = useState<number>(-1);
  const [phoneNumber, setPhoneNumber] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setVehicles(clientEdit.vehicles);
    setPhoneNumber(clientEdit.phoneNumber);
  }, [clientEdit]);

  useEffect(() => {
    if (id) {
      dispatch(getClient(id));
    }
    return () => {
      dispatch(clearClientState());
    };
  }, [id, dispatch]);

  const handleAddVehicle = (vehicle: IVehicle) => {
    const newVehicles = [...vehicles, vehicle];
    setVehicles(newVehicles);
  };

  const handleRemoveVehicle = () => {
    const newVehicles = [...vehicles];
    newVehicles.splice(indexVehicleToEdit, 1);
    setVehicles(newVehicles);
    setIndexVehicleToEdit(-1);
  };

  const handleEditVehicle = (vehicleEdited: IVehicle) => {
    const newVehicles = [...vehicles];
    newVehicles[indexVehicleToEdit] = vehicleEdited;
    setVehicles(newVehicles);
    setIndexVehicleToEdit(-1);
  };

  return loadingClient ? (
    <Loading />
  ) : (
    <>
      <PageHeader
        title={`${id ? 'Editar' : 'Criar'} Cliente`}
        backRouter={pages.CLIENTS}
      />
      <Formik
        // enableReinitialize
        initialValues={{
          name: clientEdit.name,
          phoneNumber: clientEdit.phoneNumber,
          vehicles: [],
        }}
        onSubmit={(client: IClientBody) => {
          client.phoneNumber = phoneNumber;
          client.vehicles = vehicles;
          const clientToSave = id ? { ...clientEdit, ...client } : client;
          dispatch(saveClient(clientToSave, navigate));
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <label>Nome</label>
              <Input
                placeholder="Nome"
                value={values.name}
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Field>
            <Form.Field>
              <label>Celular</label>
              <PhoneInput
                maxLength="15"
                placeholder="Celular"
                name="phoneNumber"
                country="BR"
                value={phoneNumber}
                onChange={(value: string) => setPhoneNumber(value)}
              />
            </Form.Field>
            <Title fontSize={1.5}>Veiculos</Title>

            <List>
              {vehicles.map((vehicle, index) => (
                <List.Item
                  key={vehicle.license_plate}
                  onClick={() => setIndexVehicleToEdit(index)}
                >
                  <VehicleCard vehicle={vehicle} />
                </List.Item>
              ))}
              {!vehicles.length && (
                <List.Item>
                  <p>Nenhum veiculo cadastrado.</p>
                </List.Item>
              )}
            </List>
            <VehicleEdit
              onEditVehicle={handleEditVehicle}
              onRemoveVehicle={handleRemoveVehicle}
              onCancelEditVehicle={() => setIndexVehicleToEdit(-1)}
              onAddVehicle={handleAddVehicle}
              vehicle={vehicles[indexVehicleToEdit]}
            />
            <br />
            <Button floated="right" primary type="submit" loading={saveLoading}>
              Salvar
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ClientEdit;
