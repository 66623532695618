import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select } from 'semantic-ui-react';
import { IState } from '../../../config/store/MainReducer';
import { IVehicleCategory } from '../../../models/vehicle';
import { getVehicleCategories } from '../../shared/store/actions';

interface IProps {
  value: IVehicleCategory | undefined;
  disabled: boolean;
  onSelect: (category: IVehicleCategory) => any;
}

const VehicleCategorySelect = ({ value, onSelect, disabled }: IProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVehicleCategories());
  }, [dispatch]);

  const { vehicleCategories } = useSelector(({ shared }: IState) => ({
    vehicleCategories: shared.vehicleCategories,
  }));

  const categoryOptions = useMemo(() => {
    return vehicleCategories.map((vehicle) => ({
      key: vehicle.id,
      text: vehicle.name,
      value: vehicle.id,
    }));
  }, [vehicleCategories]);

  return (
    <Form.Field>
      <label>Categoria</label>
      <Select
        disabled={disabled}
        placeholder="Selecione a categoria"
        options={categoryOptions}
        value={value?.id}
        onChange={(_, { value }) => {
          const categoryId = value as string;
          const category = vehicleCategories.find(
            (category) => category.id === categoryId
          );
          if (category) {
            onSelect(category);
          }
        }}
      />
    </Form.Field>
  );
};

export default VehicleCategorySelect;
