const baseHeaders = (othersHeaders: any) => {
  const headers: any = {
    'Content-type': 'application/json'
  }
  return { ...headers, ...othersHeaders }
}

async function handleError(response: Response) {
  if (!response.ok) {
    const clone = response.clone()
    const error = await clone.json()
    //TODO: Treate Error;
    return Promise.reject(error)
  }
  return response
}

export const Get = async (url: string, query?: any, header?: any): Promise<Response> => {
  const headers = baseHeaders(header)
  const urlSearchParams: string = query ? `?${new URLSearchParams(Object.keys(query).map(key => [key, query[key]])).toString()} ` : ''
  const originalFetch = fetch(url + urlSearchParams, {
    method: 'GET',
    headers
  })

  return await originalFetch.then(response => handleError(response))
}

export const Delete = async (url: string, header?: any) => {
  const headers = baseHeaders(header)
  const originalFetch = fetch(url, {
    method: 'DELETE',
    headers
  })

  return await originalFetch.then(response => handleError(response))
}

export const Post = async (url: string, data?: any, header?: any) => {
  const headers = baseHeaders(header)
  const params: RequestInit = {
    method: 'POST',
    headers
  }
  if (data) params.body = JSON.stringify(data)
  const originalFetch = fetch(url, params)

  return await originalFetch.then(response => handleError(response))
}

export const Patch = async (url: string, data?: any, header?: any) => {
  const headers = baseHeaders(header)
  const params: RequestInit = {
    method: 'PATCH',
    headers
  }
  if (data) params.body = JSON.stringify(data)
  const originalFetch = fetch(url, params)

  return await originalFetch.then(response => handleError(response))
}
