import Store from '../../config/store/store.config';
import { IProduct, IProductBody } from '../../models/product.model';
import { URLS } from '../../utils/constants/urls';
import { Delete, Get, Patch, Post } from '../api/Api';

const getSlug = () => {
  return Store.getState().login.userLogged?.context?.slug;
};

const ProductApi = {
  list: async (): Promise<IProduct[]> => {
    const response = await Get(URLS.PRODUCTS(getSlug()));
    if (response.ok) {
      const products = await response.json();
      return products;
    } else {
      return Promise.reject(await response.json());
    }
  },
  remove: async (id: string): Promise<void> => {
    const response = await Delete(URLS.PRODUCT(getSlug(), id));
    if (!response.ok) {
      return Promise.reject(await response.json());
    }
  },
  create: async (product: IProductBody): Promise<IProduct> => {
    const response = await Post(URLS.PRODUCTS(getSlug()), product);
    if (response.ok) {
      const product = await response.json();
      return product;
    } else {
      return Promise.reject(await response.json());
    }
  },
  get: async (id: string): Promise<IProduct> => {
    const response = await Get(URLS.PRODUCT(getSlug(), id));
    if (response.ok) {
      const product = await response.json();
      return product;
    } else {
      return Promise.reject(await response.json());
    }
  },
  update: async (product: IProduct): Promise<IProduct> => {
    const response = await Patch(URLS.PRODUCT(getSlug(), product.id), product);
    if (response.ok) {
      const product = await response.json();
      return product;
    } else {
      return Promise.reject(await response.json());
    }
  },
};

export default ProductApi;
