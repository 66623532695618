import { IVehicleCategory } from '../../../models/vehicle';
import { SHARED_ACTIONS } from './reducer';

export const changeSideBarToggle = (sideBarToggle: boolean) => ({
  type: SHARED_ACTIONS.CHANGE_SIDEBAR_TOGGLE,
  payload: { sideBarToggle },
});

export const changeHideSideBarToggle = (hideSideBarToggle: boolean) => ({
  type: SHARED_ACTIONS.CHANGE_HIDE_SIDEBAR_TOGGLE,
  payload: { hideSideBarToggle },
});


export const getVehicleCategories = () => ({
  type: SHARED_ACTIONS.ASYNC_GET_VEHICLE_CATEGORIES
});

export const changeVehicleCategories = (vehicleCategories: IVehicleCategory[]) => ({
  type: SHARED_ACTIONS.CHANGE_VEHICLE_CATEGORIES,
  payload: { vehicleCategories },
});


