import { Button, Card } from 'semantic-ui-react';
import styled from 'styled-components';

export const AddFloatButton = styled(Button)`
  position: fixed;
  right: 2em;
  bottom: 2em;
`;

export const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StatisticResume = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 1.3em;
  margin: 1em 0;
`;

export const SubHeaderText = styled.div`
  font-size: 1.2em;
`;

export const ExpenseActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FooterContent = styled(Card.Content)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:after {
    display: none !important;
  }
`;

export const ExpenseElement = styled(Card)`
  width: 100% !important;
  margin: 0 !important;
`;

export const ExpenseElementHeader = styled(Card.Header)`
  display: flex;
  justify-content: space-between;
`;
