import Store from '../../config/store/store.config';
import { IDashboardStatistic } from '../../models/dashboard.model';
import { URLS } from '../../utils/constants/urls';
import { Get } from '../api/Api';

const getSlug = () => {
  return Store.getState().login.userLogged?.context?.slug;
};

const DashboardApi = {
  /**
   * @param startDate YYYY-MM-DD
   * @param endDate YYYY-MM-DD
   */
  get: async (
    startDate?: string,
    endDate?: string
  ): Promise<IDashboardStatistic> => {
    const response = await Get(URLS.DASHBOARD(getSlug()), {
      startDate,
      endDate,
    });
    if (response.ok) {
      const products = await response.json();
      return products;
    } else {
      return Promise.reject(await response.json());
    }
  },
};

export default DashboardApi;
