import { IAction } from '../../../models/action.model';
import { IEmployee } from '../../../models/employee.model'
export enum EMPLOYEE_ACTIONS {
  ASYNC_GET_EMPLOYEES = '@EMPLOYEES_ASYNC_GET_EMPLOYEES',
  CLEAR_STATE = '@EMPLOYEES_CLEAR_STATE',
  CHANGE_LOADING = '@EMPLOYEES_CHANGE_LOADING',
  CHANGE_SAVE_LOADING = '@EMPLOYEES_CHANGE_SAVE_LOADING',
  ASYNC_SAVE_EMPLOYEE = '@EMPLOYEES_ASYNC_SAVE_EMPLOYEE',
  ASYNC_GET_EMPLOYEE = '@EMPLOYEES_ASYNC_GET_EMPLOYEE',
  CHANGE_EMPLOYEES = '@EMPLOYEES_CHANGE_EMPLOYEES',
  CHANGE_EMPLOYEE_EDIT = '@EMPLOYEES_CHANGE_EMPLOYEE_EDIT',
  CLEAR_EMPLOYEE_STATE = '@EMPLOYEES_CLEAR_EMPLOYEE_STATE',
  ASYNC_REMOVE_EMPLOYEE = '@EMPLOYEES_ASYNC_REMOVE_EMPLOYEE',
}

const INITIAL_STATE = {
  loading: false,
  saveLoading: false,
  employees: [] as IEmployee[],
  employeeEdit: {} as IEmployee
};

export type IEmployeeState = typeof INITIAL_STATE;

const EmployeeReducer = (
  state = INITIAL_STATE,
  action: IAction<EMPLOYEE_ACTIONS, IEmployeeState>
): IEmployeeState => {
  const { type, payload } = action;
  switch (type) {
    case EMPLOYEE_ACTIONS.CHANGE_LOADING:
      return { ...state, loading: payload.loading };

    case EMPLOYEE_ACTIONS.CHANGE_EMPLOYEE_EDIT:
      return { ...state, employeeEdit: payload.employeeEdit };

    case EMPLOYEE_ACTIONS.CLEAR_EMPLOYEE_STATE:
      return { ...INITIAL_STATE };


    case EMPLOYEE_ACTIONS.CHANGE_SAVE_LOADING:
      return { ...state, saveLoading: payload.saveLoading };

    case EMPLOYEE_ACTIONS.CHANGE_EMPLOYEES:
      return { ...state, employees: payload.employees };

    case EMPLOYEE_ACTIONS.CLEAR_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default EmployeeReducer;