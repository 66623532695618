import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmptyContent from '../../../../components/EmptyContent/EmptyContent';
import { IState } from '../../../../config/store/MainReducer';
import { IClient } from '../../../../models/client.model';
import ClientCard from '../../components/client-card/ClientCard';
import { getClientsList } from '../../store/actions';
import { ClientItem, ClientsListWrapper } from './ClientsList.style';

interface IProps {
  noAction?: boolean;
  onClickCard?(client: IClient): void;
}

const ClientsList = ({ noAction, onClickCard = () => {} }: IProps) => {
  const dispatch = useDispatch();

  const clients: IClient[] = useSelector(
    (state: IState) => state.client.clients
  );

  useEffect(() => {
    dispatch(getClientsList());
  }, [dispatch]);

  return (
    <ClientsListWrapper columns="equal">
      {clients.length > 0 ? (
        clients.map((client) => (
          <ClientItem key={client.id} onClick={() => onClickCard(client)}>
            <ClientCard client={client} noAction={noAction} />
          </ClientItem>
        ))
      ) : (
        <EmptyContent message="Nenhum cliente cadastrado"></EmptyContent>
      )}
    </ClientsListWrapper>
  );
};

export default ClientsList;
