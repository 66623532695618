import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  changeLoginError,
  changeLoginLoading,
  changeUserLogged,
} from './actions';
import { IUserLogged, LOGIN_ACTIONS } from './reducer';
import { pages } from '../../../utils/constants/pages';
import AuthApi from '../../../services/auth/Auth';
import Storage from '../../../services/storage/Storage';

export function* doLogin({ payload: credentials, navigate }: any) {
  try {
    yield put(changeLoginLoading(true));
    const user: IUserLogged = yield call(AuthApi.login, credentials);
    yield put(changeUserLogged(user));
    yield call(navigate, pages.EMPLOYEES);
  } catch (error: any) {
    yield put(changeLoginError(error.message));
    return;
  } finally {
    yield put(changeLoginLoading(false));
  }
}

export function* doLogout({ navigate }: any) {
  try {
    yield call(Storage.clear);
    yield call(navigate, pages.LOGIN);
  } catch (error) {
    console.error(error);
  }
}
export default function* LoginSaga() {
  const doLoginSaga: unknown = yield takeEvery(
    LOGIN_ACTIONS.ASYNC_LOGIN,
    doLogin
  );

  const doLogoutSaga: unknown = yield takeEvery(
    LOGIN_ACTIONS.ASYNC_LOGOUT,
    doLogout
  );

  yield all([doLoginSaga, doLogoutSaga]);
}
