import { createStore, applyMiddleware } from 'redux';

import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';

import { RootSaga } from './MainSaga';
import { RootReducer } from './MainReducer';
import { LOGIN_ACTIONS } from '../../pages/login/store/reducer';

const sagaMiddleware = createSagaMiddleware();

const history = createBrowserHistory();
const historyMiddleware = routerMiddleware(history);

const devTools =
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__();

const RootReducerWrapper = (state: any, action: any) => {
    if (action.type && action.type === LOGIN_ACTIONS.ASYNC_LOGOUT) {
        state = undefined;
    }

    return RootReducer(state, action);
};

const Store = applyMiddleware(sagaMiddleware, historyMiddleware)(createStore)(
    RootReducerWrapper,
    devTools
);

sagaMiddleware.run(RootSaga);

export { history };

export default Store;