import { IAction } from '../../../models/action.model';
import { IVehicleCategory } from '../../../models/vehicle';

export enum SHARED_ACTIONS {
  CHANGE_SIDEBAR_TOGGLE = '@SHARED_CHANGE_SIDEBAR_TOGGLE',
  CHANGE_HIDE_SIDEBAR_TOGGLE = '@SHARED_CHANGE_HIDE_SIDEBAR_TOGGLE',
  CLEAR_STATE = '@SHARED_CLEAR_STATE',
  ASYNC_GET_VEHICLE_CATEGORIES = '@SHARED_ASYNC_GET_VEHICLE_CATEGORIES',
  CHANGE_VEHICLE_CATEGORIES = '@SHARED_CHANGE_VEHICLE_CATEGORIES'
}

const INITIAL_STATE = {
  sideBarToggle: false,
  hideSideBarToggle: false,
  vehicleCategories: [] as IVehicleCategory[]
};

export type ISharedState = typeof INITIAL_STATE;

const SharedReducer = (
  state = INITIAL_STATE,
  action: IAction<SHARED_ACTIONS, ISharedState>
): ISharedState => {
  const { type, payload } = action;
  switch (type) {
    case SHARED_ACTIONS.CHANGE_SIDEBAR_TOGGLE:
      return { ...state, sideBarToggle: payload.sideBarToggle };

    case SHARED_ACTIONS.CHANGE_HIDE_SIDEBAR_TOGGLE:
      return { ...state, hideSideBarToggle: payload.hideSideBarToggle };

    case SHARED_ACTIONS.CHANGE_VEHICLE_CATEGORIES:
      return { ...state, vehicleCategories: payload.vehicleCategories };

    case SHARED_ACTIONS.CLEAR_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default SharedReducer;