import { theme } from '../../styles/theme';

interface IProps {
  width: number;
  height: number;
  color?: string;
}

function IconCar({
  width,
  height,
  color = theme.color.secondary,
}: IProps): JSX.Element {
  return (
    <svg width={width} height={height} viewBox="0 0 50 50" fill="none">
      <path
        d="M10.8332 32.5H9.16656C7.78584 32.5 6.66656 33.6193 6.66656 35V35.8333C6.66656 37.2141 7.78584 38.3333 9.16656 38.3333H10.8332C12.214 38.3333 13.3332 37.2141 13.3332 35.8333V35C13.3332 33.6193 12.214 32.5 10.8332 32.5ZM11.6666 35.8333C11.6666 36.2935 11.2934 36.6667 10.8332 36.6667H9.16656C8.70636 36.6667 8.33323 36.2935 8.33323 35.8333V35C8.33323 34.5398 8.70636 34.1667 9.16656 34.1667H10.8332C11.2934 34.1667 11.6666 34.5398 11.6666 35V35.8333Z"
        fill={color}
      ></path>
      <path
        d="M40.8332 32.5H39.1666C37.7858 32.5 36.6666 33.6193 36.6666 35V35.8333C36.6666 37.2141 37.7858 38.3333 39.1666 38.3333H40.8332C42.214 38.3333 43.3332 37.2141 43.3332 35.8333V35C43.3332 33.6193 42.214 32.5 40.8332 32.5ZM41.6666 35.8333C41.6666 36.2935 41.2934 36.6667 40.8332 36.6667H39.1666C38.7064 36.6667 38.3332 36.2935 38.3332 35.8333V35C38.3332 34.5398 38.7064 34.1667 39.1666 34.1667H40.8332C41.2934 34.1667 41.6666 34.5398 41.6666 35V35.8333Z"
        fill={color}
      ></path>
      <path
        d="M32.4999 32.5H17.4999C16.1192 32.5 14.9999 33.6193 14.9999 35V35.8333C14.9999 37.2141 16.1192 38.3333 17.4999 38.3333H32.4999C33.8806 38.3333 34.9999 37.2141 34.9999 35.8333V35C34.9999 33.6193 33.8806 32.5 32.4999 32.5ZM33.3332 35.8333C33.3332 36.2935 32.9601 36.6667 32.4999 36.6667H17.4999C17.0397 36.6667 16.6666 36.2935 16.6666 35.8333V35C16.6666 34.5398 17.0397 34.1667 17.4999 34.1667H32.4999C32.9601 34.1667 33.3332 34.5398 33.3332 35V35.8333Z"
        fill={color}
      ></path>
      <path
        d="M44.0466 30.0542L42.1991 29.315L36.6724 21.7158C35.8868 20.6394 34.635 20.0021 33.3024 20H16.6974C15.3648 20.0021 14.113 20.6394 13.3274 21.7158L7.80074 29.315L5.95324 30.0542C4.36731 30.6824 3.32783 32.2175 3.33324 33.9233V48.3333C3.33324 49.2539 4.07939 50 4.99991 50H9.99991C10.9204 50 11.6666 49.2539 11.6666 48.3333V45H38.3332V48.3333C38.3332 49.2539 39.0794 50 39.9999 50H44.9999C45.9204 50 46.6666 49.2539 46.6666 48.3333V33.9233C46.672 32.2175 45.6325 30.6824 44.0466 30.0542ZM14.6749 22.6967C15.1463 22.0505 15.8976 21.6679 16.6974 21.6667H33.3024C34.1022 21.6679 34.8536 22.0505 35.3249 22.6967L40.0299 29.1667H9.96991L14.6749 22.6967ZM9.99991 48.3333H4.99991V44.8575C5.06991 44.8825 5.14574 44.8958 5.21908 44.9142C5.26741 44.9267 5.31408 44.9433 5.36408 44.9525C5.51856 44.9831 5.67574 44.9991 5.83324 45H9.99991V48.3333ZM44.9999 48.3333H39.9999V45H44.1666C44.3241 44.9991 44.4813 44.9831 44.6357 44.9525C44.6857 44.9433 44.7324 44.9267 44.7816 44.9142C44.8541 44.8958 44.9299 44.8825 44.9999 44.8575V48.3333ZM44.9999 42.5C44.9999 42.9602 44.6268 43.3333 44.1666 43.3333H10.8332H5.83324C5.37303 43.3333 4.99991 42.9602 4.99991 42.5V41.6667H44.9999V42.5ZM44.9999 40H4.99991V33.9233C4.99637 32.9 5.62001 31.9791 6.57158 31.6025L8.49324 30.8333H41.5066L43.4282 31.6025C44.3798 31.9791 45.0035 32.9 44.9999 33.9233V40Z"
        fill={color}
      ></path>
    </svg>
  );
}

export default IconCar;
