import { styled } from '../../styles/theme';
import { Input, InputProps } from 'semantic-ui-react'

export const InputWrapper = styled(Input) <InputProps>`
 margin-bottom: .5em;
 font-size: 1.2em;
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.color.error};
  margin: -0.2em 0 0 0.5em;
  font-size: 12px;
`