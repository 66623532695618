import Store from '../../config/store/store.config';
import { IExpense } from '../../models/expense.model';
import { URLS } from '../../utils/constants/urls';
import { Delete, Get, Patch, Post } from '../api/Api';

const getSlug = () => {
  return Store.getState().login.userLogged?.context?.slug;
};

const ExpenseApi = {
  /**
   * @param startDate YYYY-MM-DD
   * @param endDate YYYY-MM-DD
   */
  list: async (startDate?: string, endDate?: string): Promise<IExpense[]> => {
    const response = await Get(URLS.EXPENSES(getSlug()), {
      startDate,
      endDate,
    });
    if (response.ok) {
      const expenses = await response.json();
      return expenses;
    } else {
      return Promise.reject(await response.json());
    }
  },
  remove: async (id: string): Promise<void> => {
    const response = await Delete(URLS.EXPENSE(getSlug(), id));
    if (!response.ok) {
      return Promise.reject(await response.json());
    }
  },
  create: async (expense: IExpense): Promise<IExpense> => {
    const response = await Post(URLS.EXPENSES(getSlug()), expense);
    if (response.ok) {
      const expense = await response.json();
      return expense;
    } else {
      return Promise.reject(await response.json());
    }
  },
  get: async (id: string): Promise<IExpense> => {
    const response = await Get(URLS.EXPENSE(getSlug(), id));
    if (response.ok) {
      const expense = await response.json();
      return expense;
    } else {
      return Promise.reject(await response.json());
    }
  },
  update: async (expense: IExpense): Promise<IExpense> => {
    if (!expense.id) return Promise.reject('Expense ID is required');
    const response = await Patch(URLS.EXPENSE(getSlug(), expense.id), expense);
    if (response.ok) {
      const expense = await response.json();
      return expense;
    } else {
      return Promise.reject(await response.json());
    }
  },
};

export default ExpenseApi;
