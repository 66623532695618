import Store from '../../config/store/store.config'
import { IClient, IClientBody } from '../../models/client.model'
import { URLS } from '../../utils/constants/urls'
import { Get, Post } from '../api/Api'
import { Delete, Patch } from '../api/Api'

const getSlug = () => {
  return Store.getState().login.userLogged?.context?.slug
}

const ClientApi = {
  list: async (): Promise<IClient[]> => {
    const response = await Get(URLS.CLIENTS(getSlug()))
    if (response.ok) {
      const clients = await response.json()
      return clients
    } else {
      return Promise.reject(await response.json())
    }
  },
  remove: async (id: string): Promise<void> => {
    const response = await Delete(URLS.CLIENT(getSlug(), id))
    if (!response.ok) {
      return Promise.reject(await response.json())
    }
  },
  create: async (client: IClientBody): Promise<IClient> => {
    const response = await Post(URLS.CLIENTS(getSlug()), client)
    if (response.ok) {
      const client = await response.json()
      return client
    } else {
      return Promise.reject(await response.json())
    }
  },
  get: async (id: string): Promise<IClient> => {
    const response = await Get(URLS.CLIENT(getSlug(), id))
    if (response.ok) {
      const client = await response.json()
      return client
    } else {
      return Promise.reject(await response.json())
    }
  },
  update: async (client: IClient): Promise<IClient> => {
    const response = await Patch(URLS.CLIENT(getSlug(), client.id as string), client)
    if (response.ok) {
      const client = await response.json()
      return client
    } else {
      return Promise.reject(await response.json())
    }
  }
}

export default ClientApi
