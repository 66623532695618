import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { BsCreditCard2Back } from 'react-icons/bs';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { ImEnter } from 'react-icons/im';
import {
  MdOutlineAttachMoney,
  MdOutlineMoneyOff,
  MdOutlineSupervisedUserCircle,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import IconCar from '../../components/Icon/IconCar';
import Loading from '../../components/Loading/Loading';
import MoneyDisplay from '../../components/MoneyDisplay/MoneyDisplay';
import PageHeader from '../../components/PageTitle/PageTitle';
import { IState } from '../../config/store/MainReducer';
import { pages } from '../../utils/constants/pages';
import {
  ActionButton,
  ActionButtonContent,
  ActionLabel,
  ActionsContent,
  BigInfo,
  BigInfoTitle,
  BigInfoValue,
  BlueEffect,
  CarsResumeContent,
  CarsResumeDivisor,
  DashboardContainer,
  DashboardContent,
  InfoContent,
  RangeButton,
  RevenueDateRange,
  RevenueDetail,
  RevenueDetailContent,
  RevenuesHeader,
  RevenueTitle,
  SmallInfo,
  SmallInfoTitle,
  SmallInfoValue,
  TotalRevenue,
} from './Dashboard.style';
import { getDashboardStatistic } from './store/actions';

enum DashboardFilter {
  DAY,
  WEEK,
  MONTH,
}

const DashboardPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentFilter, changeCurrentFilter] = useState(DashboardFilter.DAY);

  const goToOrderList = useCallback(() => {
    navigate(pages.ORDER);
  }, [navigate]);

  const goToOrderCreate = useCallback(() => {
    navigate(pages.ORDER_CREATE);
  }, [navigate]);

  const goToClientList = useCallback(() => {
    navigate(pages.CLIENTS);
  }, [navigate]);

  const goToExpenseList = useCallback(() => {
    navigate(pages.EXPENSE);
  }, [navigate]);

  const { dashboardStatistic, loading } = useSelector((state: IState) => {
    return {
      dashboardStatistic: state.dashboard.dashboardStatistic,
      loading: state.dashboard.loading,
    };
  });

  useEffect(() => {
    let startDate, endDate;
    switch (currentFilter) {
      case DashboardFilter.DAY:
        startDate = moment().startOf('day').format('YYYY-MM-DD');
        endDate = moment().endOf('day').format('YYYY-MM-DD');
        break;
      case DashboardFilter.WEEK:
        startDate = moment().startOf('week').format('YYYY-MM-DD');
        endDate = moment().endOf('week').format('YYYY-MM-DD');
        break;
      case DashboardFilter.MONTH:
        startDate = moment().startOf('month').format('YYYY-MM-DD');
        endDate = moment().endOf('month').format('YYYY-MM-DD');
        break;
    }
    dispatch(getDashboardStatistic(startDate, endDate));
  }, [dispatch, currentFilter]);

  return (
    <DashboardContainer>
      <BlueEffect />
      <DashboardContent>
        <PageHeader title="" iconContrast={true} />
        {loading ? (
          <Loading />
        ) : (
          <>
            <RevenuesHeader>
              <RevenueTitle>Faturamento</RevenueTitle>
              <RevenueDateRange>
                <RangeButton
                  className={
                    currentFilter === DashboardFilter.DAY ? 'active' : ''
                  }
                  onClick={() => changeCurrentFilter(DashboardFilter.DAY)}
                >
                  Dia
                </RangeButton>
                <RangeButton
                  className={
                    currentFilter === DashboardFilter.WEEK ? 'active' : ''
                  }
                  onClick={() => changeCurrentFilter(DashboardFilter.WEEK)}
                >
                  Sem
                </RangeButton>
                <RangeButton
                  className={
                    currentFilter === DashboardFilter.MONTH ? 'active' : ''
                  }
                  onClick={() => changeCurrentFilter(DashboardFilter.MONTH)}
                >
                  Mês
                </RangeButton>
              </RevenueDateRange>
            </RevenuesHeader>
            <TotalRevenue>
              <MoneyDisplay
                value={dashboardStatistic.payment.total}
              ></MoneyDisplay>
            </TotalRevenue>
            <RevenueDetailContent>
              <RevenueDetail>
                <FaRegMoneyBillAlt />
                <MoneyDisplay
                  value={dashboardStatistic.payment.totalCash}
                ></MoneyDisplay>
              </RevenueDetail>
              <RevenueDetail>
                <MdOutlineAttachMoney />
                <MoneyDisplay
                  value={dashboardStatistic.payment.totalPaid}
                ></MoneyDisplay>
              </RevenueDetail>
              <RevenueDetail>
                <BsCreditCard2Back />
                <MoneyDisplay
                  value={
                    dashboardStatistic.payment.totalCredit +
                    dashboardStatistic.payment.totalDebit
                  }
                ></MoneyDisplay>
              </RevenueDetail>
              <RevenueDetail>
                <MdOutlineMoneyOff />
                <MoneyDisplay
                  value={dashboardStatistic.expense.total}
                ></MoneyDisplay>
              </RevenueDetail>
            </RevenueDetailContent>
            <CarsResumeContent>
              <InfoContent>
                <BigInfo>
                  <BigInfoTitle>No Pátio</BigInfoTitle>
                  <BigInfoValue>
                    {dashboardStatistic.order.totalPending}
                  </BigInfoValue>
                </BigInfo>
                <BigInfo>
                  <BigInfoTitle>N&ordm; Total</BigInfoTitle>
                  <BigInfoValue>{dashboardStatistic.order.total}</BigInfoValue>
                </BigInfo>
              </InfoContent>
              <CarsResumeDivisor />
              <InfoContent>
                {dashboardStatistic.vehicleCategoryList.map(
                  (vehicleCategory) => {
                    return (
                      <SmallInfo key={vehicleCategory.id}>
                        <SmallInfoTitle>{vehicleCategory.name}</SmallInfoTitle>
                        <SmallInfoValue>{vehicleCategory.total}</SmallInfoValue>
                      </SmallInfo>
                    );
                  }
                )}
              </InfoContent>
            </CarsResumeContent>
            <ActionsContent>
              <ActionButtonContent>
                <ActionButton onClick={goToOrderCreate}>
                  <ImEnter fontSize={35} />
                  <ActionLabel>Entrada</ActionLabel>
                </ActionButton>
              </ActionButtonContent>
              <ActionButtonContent>
                <ActionButton onClick={goToOrderList}>
                  <IconCar width={40} height={40} />
                  <ActionLabel>Pátio</ActionLabel>
                </ActionButton>
              </ActionButtonContent>
              <ActionButtonContent>
                <ActionButton onClick={goToClientList}>
                  <MdOutlineSupervisedUserCircle fontSize={35} />
                  <ActionLabel>Clientes</ActionLabel>
                </ActionButton>
              </ActionButtonContent>
              <ActionButtonContent>
                <ActionButton onClick={goToExpenseList}>
                  <MdOutlineMoneyOff fontSize={35} />
                  <ActionLabel>Despesas</ActionLabel>
                </ActionButton>
              </ActionButtonContent>
            </ActionsContent>
          </>
        )}
      </DashboardContent>
    </DashboardContainer>
  );
};

export default DashboardPage;
