import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { IState } from '../../../config/store/MainReducer';
import { IExpense } from '../../../models/expense.model';
import ExpenseApi from '../../../services/expense/Expense';
import { pages } from '../../../utils/constants/pages';
import {
  changeExpenseEdit,
  changeExpenses,
  changeLoading,
  changeSaveLoading,
  clearExpenseState,
} from './actions';
import { EXPENSE_ACTIONS } from './reducer';

function* getExpenseList({ payload }: any) {
  try {
    yield put(changeLoading(true));
    const { startDate, endDate } = payload;
    const expenses: IExpense[] = yield call(
      ExpenseApi.list,
      startDate,
      endDate
    );
    yield put(changeExpenses(expenses));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(changeLoading(false));
  }
}

function* getExpense({ payload }: any) {
  try {
    yield put(changeLoading(true));
    const expense: IExpense = yield call(ExpenseApi.get, payload.id);
    yield put(changeExpenseEdit(expense));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(changeLoading(false));
  }
}

function* saveExpense({ payload }: any) {
  try {
    yield put(changeSaveLoading(true));
    const expenses: IExpense[] = yield select(
      (state: IState) => state.expense.expenseList
    );
    if (payload.expense.id) {
      const expense: IExpense = yield call(ExpenseApi.update, payload.expense);
      const index = expenses.findIndex(({ id }) => id === expense.id);
      expenses[index] = expense;
      yield put(changeExpenses([...expenses]));
    } else {
      const expense: IExpense = yield call(ExpenseApi.create, payload.expense);
      yield put(changeExpenses([...expenses, expense]));
    }
    yield put(clearExpenseState());
    yield call(payload.navigate, pages.EXPENSE);
  } catch (error: any) {
    alert(error.message);
    console.error(error);
  } finally {
    yield put(changeSaveLoading(false));
  }
}

function* removeExpense({ payload }: any) {
  try {
    yield put(changeLoading(true));
    const expenses: IExpense[] = yield select(
      (state: IState) => state.expense.expenseList
    );
    yield call(ExpenseApi.remove, payload.id);
    const index = expenses.findIndex(({ id }) => id === payload.id);
    if (index !== -1) {
      expenses.splice(index, 1);
    }
    yield put(changeExpenses([...expenses]));
  } catch (error: any) {
    alert(error.message);
    console.error(error);
  } finally {
    yield put(changeLoading(false));
  }
}

export default function* ExpenseSaga() {
  const getExpenseListSaga: unknown = yield takeEvery(
    EXPENSE_ACTIONS.ASYNC_GET_EXPENSES,
    getExpenseList
  );

  const saveExpenseSaga: unknown = yield takeEvery(
    EXPENSE_ACTIONS.ASYNC_SAVE_EXPENSE,
    saveExpense
  );

  const getExpenseSaga: unknown = yield takeEvery(
    EXPENSE_ACTIONS.ASYNC_GET_EXPENSE,
    getExpense
  );

  const removeExpenseSaga: unknown = yield takeEvery(
    EXPENSE_ACTIONS.ASYNC_REMOVE_EXPENSE,
    removeExpense
  );

  yield all([
    getExpenseListSaga,
    saveExpenseSaga,
    getExpenseSaga,
    removeExpenseSaga,
  ]);
}
