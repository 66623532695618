import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { IState } from '../config/store/MainReducer';
import Storage, { StorageKeys } from '../services/storage/Storage';
import { pages } from '../utils/constants/pages';
import ClientPage from './client/Client';
import ClientEdit from './client/edit/ClientEdit';
import ClientListPage from './client/list/ClientsListPage';
import DashboardPage from './dashboard/Dashboard';
import EmployeeEdit from './employee/edit/EmployeeEdit';
import EmployeePage from './employee/Employee';
import EmployeeListPage from './employee/list/EmployeesList';
import ExpenseEdit from './expense/edit/ExpenseEdit';
import ExpensePage from './expense/Expense';
import ExpenseListPage from './expense/list/ExpenseList';
import Home from './home/Home';
import Login from './login/Login';
import { changeUserLogged } from './login/store/actions';
import OrderEdit from './order/edit/OrderEdit';
import OrderListPage from './order/list/OrderList';
import OrderPage from './order/Order';
import ProductEdit from './product/edit/ProductEdit';
import ProductPage from './product/Product';
import ProductListPage from './product/list/ProductList';
import { getVehicleCategories } from './shared/store/actions';

export default function MainRouter(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const userLogged = useSelector((state: IState) => state.login.userLogged);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVehicleCategories());
  }, [dispatch]);

  useEffect(() => {
    const userLogged = Storage.get(StorageKeys.USER_LOGGED);
    if (userLogged) {
      dispatch(changeUserLogged(JSON.parse(userLogged)));
    }
    setLoading(false);
  }, [dispatch]);

  const isLogged = userLogged.email || Storage.get(StorageKeys.USER_LOGGED);

  if (loading) return <h2>loading...</h2>;
  return (
    <Routes>
      <Route
        path={pages.LOGIN}
        element={!isLogged ? <Login /> : <Navigate to={pages.HOME} />}
      ></Route>
      <Route
        path={pages.HOME}
        element={isLogged ? <Home /> : <Navigate to={pages.LOGIN} />}
      >
        <Route path={pages.EMPLOYEES} element={<EmployeePage />}>
          <Route index element={<EmployeeListPage />}></Route>
          <Route
            path={pages.EMPLOYEE_CREATE}
            element={<EmployeeEdit />}
          ></Route>
          <Route
            path={pages.EMPLOYEE_EDIT()}
            element={<EmployeeEdit />}
          ></Route>
        </Route>
        <Route path={pages.EXPENSE} element={<ExpensePage />}>
          <Route index element={<ExpenseListPage />}></Route>
          <Route path={pages.EXPENSE_CREATE} element={<ExpenseEdit />}></Route>
          <Route path={pages.EXPENSE_EDIT()} element={<ExpenseEdit />}></Route>
        </Route>
        <Route path={pages.CLIENTS} element={<ClientPage />}>
          <Route index element={<ClientListPage />}></Route>
          <Route path={pages.CLIENT_CREATE} element={<ClientEdit />}></Route>
          <Route path={pages.CLIENT_EDIT()} element={<ClientEdit />}></Route>
        </Route>
        <Route path={pages.PRODUCT} element={<ProductPage />}>
          <Route index element={<ProductListPage />}></Route>
          <Route path={pages.PRODUCT_CREATE} element={<ProductEdit />}></Route>
          <Route path={pages.PRODUCT_EDIT()} element={<ProductEdit />}></Route>
        </Route>

        <Route path={pages.ORDER} element={<OrderPage />}>
          <Route index element={<OrderListPage />}></Route>
          <Route path={pages.ORDER_CREATE} element={<OrderEdit />}></Route>
          <Route path={pages.ORDER_EDIT()} element={<OrderEdit />}></Route>
        </Route>

        <Route path={pages.DASHBOARD} element={<DashboardPage />}></Route>
        <Route index element={<DashboardPage />} />
      </Route>
      <Route
        path={'*'}
        element={<Navigate to={isLogged ? pages.LOGIN : pages.HOME} />}
      />
    </Routes>
  );
}
