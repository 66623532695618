import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { GrAdd } from 'react-icons/gr';
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'semantic-ui-react';
import Alert from '../../../components/Alert/Alert';
import EmptyContent from '../../../components/EmptyContent/EmptyContent';
import Loading from '../../../components/Loading/Loading';
import PageHeader from '../../../components/PageTitle/PageTitle';
import { IconButton } from '../../../components/PageTitle/PageTitle.style';
import { IState } from '../../../config/store/MainReducer';
import { pages } from '../../../utils/constants/pages';
import { getExpenseList, removeExpense } from '../store/actions';
import {
  AddFloatButton,
  ExpenseActions,
  ExpenseElement,
  ExpenseElementHeader,
  FooterContent,
  StatisticResume,
  SubHeader,
  SubHeaderText,
} from './ExpenseList.style';

const ExpenseListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentMonthQuery, changeCurrentMonthQuery] = useState(
    moment().format('YYYY-MM')
  );

  const { expenseList, loading } = useSelector((state: IState) => {
    return {
      expenseList: state.expense.expenseList,
      loading: state.expense.loading,
    };
  });

  useEffect(() => {
    dispatch(
      getExpenseList(
        moment(currentMonthQuery).startOf('month').format('YYYY-MM-DD'),
        moment(currentMonthQuery).endOf('month').format('YYYY-MM-DD')
      )
    );
  }, [dispatch, currentMonthQuery]);

  const totalCurrentValue = useMemo(() => {
    return expenseList.reduce((acc, curr) => {
      return acc + (curr.value ?? 0);
    }, 0);
  }, [expenseList]);

  const goToCreate = () => {
    navigate(pages.EXPENSE_CREATE);
  };

  const goToEditPage = useCallback(
    (id: string) => {
      navigate(pages.EXPENSE_EDIT(id));
    },
    [navigate]
  );

  function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function handleDeleteExpense(id: string) {
    dispatch(removeExpense(id));
  }

  function callChangeCurrentMonthQuery(plus: number) {
    const currentMonth = moment(currentMonthQuery, 'YYYY-MM')
      .add(plus, 'month')
      .format('YYYY-MM');
    changeCurrentMonthQuery(currentMonth);
  }

  return (
    <>
      <PageHeader title="Despensas" />
      <SubHeader>
        <IconButton onClick={() => callChangeCurrentMonthQuery(-1)}>
          <IoMdArrowBack size={25} />
        </IconButton>
        <SubHeaderText>
          {capitalizeFirstLetter(
            moment(currentMonthQuery, 'YYYY-MM').format('MMMM [de] YYYY')
          )}
        </SubHeaderText>
        <IconButton onClick={() => callChangeCurrentMonthQuery(1)}>
          <IoMdArrowForward size={25} />
        </IconButton>
      </SubHeader>
      <StatisticResume>
        <span>Total: </span>
        <NumberFormat
          thousandSeparator="."
          value={totalCurrentValue}
          fixedDecimalScale={true}
          decimalSeparator=","
          decimalScale={2}
          prefix="R$ "
          displayType={'text'}
        />
      </StatisticResume>
      {loading ? (
        <Loading />
      ) : expenseList.length > 0 ? (
        expenseList.map((expense) => (
          <ExpenseElement key={expense.id}>
            <Card.Content>
              <ExpenseElementHeader>
                {expense.name} -{' '}
                <NumberFormat
                  thousandSeparator="."
                  value={expense.value}
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  decimalScale={2}
                  prefix="R$ "
                  displayType={'text'}
                />
              </ExpenseElementHeader>
              <Card.Meta>
                {expense.description}
                <p>
                  Dia {moment(expense.paymentDate, 'YYYY-MM-DD').format('DD')}
                </p>
              </Card.Meta>
            </Card.Content>
            <FooterContent>
              <ExpenseActions>
                <Button icon onClick={() => goToEditPage(expense.id)} primary>
                  <AiOutlineEdit fontSize={20} />
                </Button>
                <Alert
                  title="Excluir despesa"
                  description={`Tem certeza que deseja excluir essa despesa ${expense.name} ?`}
                  onConfirm={() => handleDeleteExpense(expense.id)}
                >
                  <Button color="red" icon basic>
                    <AiOutlineDelete fontSize={20} />
                  </Button>
                </Alert>
              </ExpenseActions>
            </FooterContent>
          </ExpenseElement>
        ))
      ) : (
        <EmptyContent message="Nenhuma despesa encontrada no mês selecionado"></EmptyContent>
      )}

      <AddFloatButton icon circular onClick={goToCreate}>
        <GrAdd />
      </AddFloatButton>
    </>
  );
};

export default ExpenseListPage;
