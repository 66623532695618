import { useCallback, useEffect, useMemo } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { GrAdd } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'semantic-ui-react';
import Alert from '../../../components/Alert/Alert';
import EmptyContent from '../../../components/EmptyContent/EmptyContent';
import IconService from '../../../components/Icon/IconService';
import Loading from '../../../components/Loading/Loading';
import PageHeader from '../../../components/PageTitle/PageTitle';
import { IState } from '../../../config/store/MainReducer';
import useWindowSize, { ENUMDevices } from '../../../hook/windowSize';
import { pages } from '../../../utils/constants/pages';
import { getProductList, removeProduct } from '../store/actions';
import {
  AddFloatButton,
  FooterContent,
  ProductActions,
  ProductCard,
  ProductItem,
  ProductList,
} from './ProductList.style';

const ProductListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { device } = useWindowSize();
  const { productList, loading } = useSelector((state: IState) => {
    return {
      productList: state.product.productList,
      loading: state.product.loading,
    };
  });

  useEffect(() => {
    dispatch(getProductList());
  }, [dispatch]);

  const cardWidth = useMemo(() => {
    return device === ENUMDevices.isMobile ? '100%' : 'unset';
  }, [device]);

  const goToCreate = () => {
    navigate(pages.PRODUCT_CREATE);
  };

  const goToEditPage = useCallback(
    (id: string) => {
      navigate(pages.PRODUCT_EDIT(id));
    },
    [navigate]
  );

  function handleDeleteProduct(id: string) {
    dispatch(removeProduct(id));
  }
  return (
    <>
      <PageHeader title="Serviços" />
      {loading ? (
        <Loading />
      ) : (
        <ProductList columns="equal">
          {productList.length > 0 ? (
            productList.map((product) => (
              <ProductItem key={product.id} styled={{ width: cardWidth }}>
                <ProductCard>
                  <Card.Content>
                    <Card.Header>{product.name}</Card.Header>
                    <Card.Meta>
                      <span className="date">
                        <IconService width={15} height={15} /> Serviço
                      </span>
                    </Card.Meta>
                    <Card.Description>
                      Descrição: {product.description}
                    </Card.Description>
                  </Card.Content>
                  <FooterContent>
                    <ProductActions>
                      <Button
                        icon
                        onClick={() => goToEditPage(product.id)}
                        primary
                      >
                        <AiOutlineEdit fontSize={20} />
                      </Button>
                      <Alert
                        title="Excluir serviço"
                        description={`Tem certeza que deseja excluir o serviço ${product.name} ?`}
                        onConfirm={() => handleDeleteProduct(product.id)}
                      >
                        <Button color="red" icon basic>
                          <AiOutlineDelete fontSize={20} />
                        </Button>
                      </Alert>
                    </ProductActions>
                  </FooterContent>
                </ProductCard>
              </ProductItem>
            ))
          ) : (
            <EmptyContent message="Nenhum serviço cadastrado"></EmptyContent>
          )}
        </ProductList>
      )}

      <AddFloatButton icon circular onClick={goToCreate}>
        <GrAdd />
      </AddFloatButton>
    </>
  );
};

export default ProductListPage;
