import { InputProps } from 'semantic-ui-react'
import { InputWrapper, ErrorMessage } from './Input.style'
export type IInputProps = InputProps

const Input = ({ errorMessage, ...rest }: InputProps) => {
  return (
    <>
      <InputWrapper error={Boolean(errorMessage)} {...rest} />
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </>
  )
}

export default Input
