import styled from 'styled-components';

export const EmptyContentContainer = styled.section`
  height: calc(100vh - 72px);
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
`;

export const EmptyContentText = styled.p`
  text-align: center;
  font-size: 20px;
`;
