
export function validEmail(email: string) {
  const errors: any = {};
  if (!email) {
    errors.email = 'Email obrigatório'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = 'Email inválido'
  }

  return errors
}
