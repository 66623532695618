import { all } from 'redux-saga/effects';
import ClientSaga from '../../pages/client/store/saga';
import DashboardSaga from '../../pages/dashboard/store/saga';
import EmployeeSaga from '../../pages/employee/store/saga';
import ExpenseSaga from '../../pages/expense/store/saga';
import LoginSaga from '../../pages/login/store/saga';
import OrderSaga from '../../pages/order/store/saga';
import ProductSaga from '../../pages/product/store/saga';
import SharedSaga from '../../pages/shared/store/saga';

export function* RootSaga(): any {
  yield all([
    LoginSaga(),
    EmployeeSaga(),
    ExpenseSaga(),
    ClientSaga(),
    SharedSaga(),
    ProductSaga(),
    OrderSaga(),
    DashboardSaga(),
  ]);
}
