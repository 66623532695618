import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import ClientReducer, { IClientState } from '../../pages/client/store/reducer';
import DashboardReducer, {
  IDashboardState,
} from '../../pages/dashboard/store/reducer';
import EmployeeReducer, {
  IEmployeeState,
} from '../../pages/employee/store/reducer';
import ExpenseReducer, {
  IExpenseState,
} from '../../pages/expense/store/reducer';
import LoginReducer, { ILoginState } from '../../pages/login/store/reducer';
import OrderReducer, { IOrderState } from '../../pages/order/store/reducer';
import ProductReducer, {
  IProductState,
} from '../../pages/product/store/reducer';
import SharedReducer, { ISharedState } from '../../pages/shared/store/reducer';

export const RootReducer = combineReducers({
  route: routerReducer,
  login: LoginReducer,
  employee: EmployeeReducer,
  expense: ExpenseReducer,
  shared: SharedReducer,
  client: ClientReducer,
  product: ProductReducer,
  order: OrderReducer,
  dashboard: DashboardReducer,
});

export interface IState {
  login: ILoginState;
  employee: IEmployeeState;
  expense: IExpenseState;
  shared: ISharedState;
  client: IClientState;
  product: IProductState;
  order: IOrderState;
  dashboard: IDashboardState;
}
