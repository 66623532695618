import { IProduct } from '../../../models/product.model'
import { PRODUCT_ACTIONS } from './reducer'

export const getProductList = () => ({
  type: PRODUCT_ACTIONS.ASYNC_GET_PRODUCTS,
})

export const saveProduct = (product: Partial<IProduct>, navigate: any) => ({
  type: PRODUCT_ACTIONS.ASYNC_SAVE_PRODUCT,
  payload: { product, navigate }
})

export const changeProducts = (productList: IProduct[]) => ({
  type: PRODUCT_ACTIONS.CHANGE_PRODUCTS,
  payload: { productList }
})

export const changeLoading = (loading: boolean) => ({
  type: PRODUCT_ACTIONS.CHANGE_LOADING,
  payload: { loading }
})

export const changeSaveLoading = (saveLoading: boolean) => ({
  type: PRODUCT_ACTIONS.CHANGE_LOADING,
  payload: { saveLoading }
})

export const clearProductState = () => ({
  type: PRODUCT_ACTIONS.CLEAR_PRODUCT_STATE
})

export const removeProduct = (id: string) => ({
  type: PRODUCT_ACTIONS.ASYNC_REMOVE_PRODUCT,
  payload: { id }
})

export const changeProductEdit = (productEdit: IProduct) => ({
  type: PRODUCT_ACTIONS.CHANGE_PRODUCT_EDIT,
  payload: { productEdit }
})

export const getProduct = (id: string) => ({
  type: PRODUCT_ACTIONS.ASYNC_GET_PRODUCT,
  payload: { id }
})
