import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { IState } from '../../../config/store/MainReducer'
import { IClient } from '../../../models/client.model'
import ClientApi from '../../../services/client/Client'
import { pages } from '../../../utils/constants/pages'
import { changeClientEdit, changeClients, changeLoading, changeSaveLoading, clearClientState } from './actions'
import { CLIENT_ACTIONS } from './reducer'

function* getClientList() {
  try {
    yield put(changeLoading(true))
    const clients: IClient[] = yield call(ClientApi.list)
    yield put(changeClients(clients))
  } catch (error) {
    console.error(error)
  } finally {
    yield put(changeLoading(false))
  }
}

function* getClient({ payload }: any) {
  try {
    yield put(changeLoading(true))
    const client: IClient = yield call(ClientApi.get, payload.id)
    yield put(changeClientEdit(client))
  } catch (error) {
    console.error(error)
  } finally {
    yield put(changeLoading(false))
  }
}

function* saveClient({ payload }: any) {
  try {
    yield put(changeSaveLoading(true))
    const clients: IClient[] = yield select(
      (state: IState) => state.client.clients
    );
    if (payload.client.id) {
      const client: IClient = yield call(ClientApi.update, payload.client)
      const index = clients.findIndex(({ id }) => id === client.id)
      clients[index] = client
      yield put(changeClients([...clients]))
    } else {
      const client: IClient = yield call(ClientApi.create, payload.client)
      yield put(changeClients([...clients, client]))
    }
    yield put(clearClientState())
    yield call(payload.navigate, pages.CLIENTS)
  } catch (error: any) {
    alert(error.message)
    console.error(error)
  } finally {
    yield put(changeSaveLoading(false))
  }
}

function* removeClient({ payload }: any) {
  try {
    yield put(changeLoading(true))
    const clients: IClient[] = yield select((state: IState) => state.client.clients)
    yield call(ClientApi.remove, payload.id)
    const index = clients.findIndex(({ id }) => id === payload.id)
    if (index !== -1) {
      clients.splice(index, 1)
    }
    yield put(changeClients([...clients]))
  } catch (error: any) {
    alert(error.message)
    console.error(error)
  } finally {
    yield put(changeLoading(false))
  }
}

export default function* ClientSaga() {
  const getClientListSaga: unknown = yield takeEvery(CLIENT_ACTIONS.ASYNC_GET_CLIENTS, getClientList)

  const saveClientSaga: unknown = yield takeEvery(CLIENT_ACTIONS.ASYNC_SAVE_CLIENT, saveClient)

  const getClientSaga: unknown = yield takeEvery(CLIENT_ACTIONS.ASYNC_GET_CLIENT, getClient)

  const removeClientSaga: unknown = yield takeEvery(CLIENT_ACTIONS.ASYNC_REMOVE_CLIENT, removeClient)

  yield all([getClientListSaga, saveClientSaga, getClientSaga, removeClientSaga])
}
