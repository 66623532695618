import styled from 'styled-components';

export const OrderProductItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;

export const OrderProductItemName = styled.div`
  flex-grow: 1;
`;

export const OrderProductItemMoney = styled.div`
  margin-right: 8px;
`;
