import moment from 'moment';
import { IAction } from '../../../models/action.model';
import { IExpense } from '../../../models/expense.model';

export enum EXPENSE_ACTIONS {
  CLEAR_STATE = '@EXPENSE_CLEAR_STATE',
  CLEAR_EXPENSE_STATE = '@CLEAR_EXPENSE_STATE',

  CHANGE_LOADING = '@EXPENSE_CHANGE_LOADING',
  CHANGE_SAVE_LOADING = '@EXPENSE_CHANGE_SAVE_LOADING',
  CHANGE_EXPENSES = '@EXPENSE_CHANGE_EXPENSES',
  CHANGE_EXPENSE_EDIT = '@CHANGE_EXPENSE_EDIT',
  CHANGE_EXPENSE = '@CHANGE_EXPENSE',

  ASYNC_GET_EXPENSES = '@EXPENSE_ASYNC_GET_EXPENSES',
  ASYNC_SAVE_EXPENSE = '@EXPENSE_ASYNC_SAVE_EXPENSE',
  ASYNC_REMOVE_EXPENSE = '@ASYNC_REMOVE_EXPENSE',
  ASYNC_GET_EXPENSE = '@ASYNC_GET_EXPENSE',
}

const INITIAL_STATE = {
  loading: false,
  saveLoading: false,
  expenseList: [] as IExpense[],
  expenseEdit: {
    name: '',
    description: '',
    paymentDate: moment().format('YYYY-MM-DD'),
    value: 0,
  } as IExpense,
};

export type IExpenseState = typeof INITIAL_STATE;

const ExpenseReducer = (
  state = INITIAL_STATE,
  action: IAction<EXPENSE_ACTIONS, IExpenseState>
): IExpenseState => {
  const { type, payload } = action;
  switch (type) {
    case EXPENSE_ACTIONS.CHANGE_LOADING:
      return { ...state, loading: payload.loading };

    case EXPENSE_ACTIONS.CHANGE_SAVE_LOADING:
      return { ...state, saveLoading: payload.saveLoading };

    case EXPENSE_ACTIONS.CHANGE_EXPENSES:
      return { ...state, expenseList: payload.expenseList };

    case EXPENSE_ACTIONS.CLEAR_STATE:
      return { ...INITIAL_STATE };

    case EXPENSE_ACTIONS.CHANGE_EXPENSE_EDIT:
      return { ...state, expenseEdit: payload.expenseEdit };

    case EXPENSE_ACTIONS.CLEAR_EXPENSE_STATE:
      return { ...INITIAL_STATE };

    case EXPENSE_ACTIONS.CHANGE_EXPENSE:
      return { ...state, expenseList: payload.expenseList };

    default:
      return state;
  }
};

export default ExpenseReducer;
