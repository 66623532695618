import styled from "styled-components";
import { Grid, Button, Card } from 'semantic-ui-react'


export const EmployeeList = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 1em;
`;

export const EmployeeItem = styled(Grid.Column)`
    display:flex;
    margin:  0 !important;
    min-width: 250px;
`;

export const AddFloatButton = styled(Button)`
    position: fixed;
    right: 2em;
    bottom: 2em;
`

export const FooterContent = styled(Card.Content)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &:after {
        display: none !important;
    }
`
export const QtdCarWashed = styled.div`
    display: flex;
    align-items: center;
`

export const QtdCarLabel = styled.span`
    margin-left: .5rem;
`
export const EmployeeActions = styled.div`
    display: flex;
    justify-content: flex-end;
`