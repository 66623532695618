import { Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import Input from '../../../components/Input/Input';
import Loading from '../../../components/Loading/Loading';
import PageHeader from '../../../components/PageTitle/PageTitle';
import { IState } from '../../../config/store/MainReducer';
import { IEmployeeBody } from '../../../models/employee.model';
import { pages } from '../../../utils/constants/pages';
import { validEmail } from '../../../utils/functions';
import {
  clearEmployeeState,
  getEmployee,
  saveEmployee,
} from '../store/actions';

const EmployeeEdit = () => {
  const { loadingEmployee, saveLoading, employeeEdit } = useSelector(
    ({ employee }: IState) => ({
      saveLoading: employee.saveLoading,
      loadingEmployee: employee.loading,
      employeeEdit: employee.employeeEdit,
    })
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getEmployee(id));
    }
    return () => {
      dispatch(clearEmployeeState());
    };
  }, [id, dispatch]);

  return loadingEmployee ? (
    <Loading />
  ) : (
    <>
      <PageHeader
        title={`${id ? 'Editar' : 'Criar'} Funcionário`}
        backRouter={pages.EMPLOYEES}
      />
      <Formik
        enableReinitialize
        initialValues={{ name: employeeEdit.name, email: employeeEdit.email }}
        validate={(values) => {
          let errors: any = {};
          errors = { ...validEmail(values.email), ...errors };
          return errors;
        }}
        onSubmit={(employee: IEmployeeBody) => {
          let employeeToSave = id ? { ...employeeEdit, ...employee } : employee;
          dispatch(saveEmployee(employeeToSave, navigate));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <label>Nome</label>
              <Input
                placeholder="Nome"
                value={values.name}
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Field>
            <Form.Field>
              <label>Email</label>
              <Input
                type="email"
                placeholder="Email"
                name="email"
                errorMessage={touched && errors.email}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Field>
            <Button primary type="submit" loading={saveLoading}>
              Salvar
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EmployeeEdit;
