import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { IState } from '../../../config/store/MainReducer'
import { IProduct } from '../../../models/product.model'
import ProductApi from '../../../services/product/Product'
import { pages } from '../../../utils/constants/pages'
import { changeProductEdit, changeProducts, changeLoading, changeSaveLoading, clearProductState } from './actions'
import { PRODUCT_ACTIONS } from './reducer'

function* getProductList() {
  try {
    yield put(changeLoading(true))
    const products: IProduct[] = yield call(ProductApi.list)
    yield put(changeProducts(products))
  } catch (error) {
    console.error(error)
  } finally {
    yield put(changeLoading(false))
  }
}

function* getProduct({ payload }: any) {
  try {
    yield put(changeLoading(true))
    const product: IProduct = yield call(ProductApi.get, payload.id)
    yield put(changeProductEdit(product))
  } catch (error) {
    console.error(error)
  } finally {
    yield put(changeLoading(false))
  }
}

function* saveProduct({ payload }: any) {
  try {
    yield put(changeSaveLoading(true))
    const products: IProduct[] = yield select((state: IState) => state.product.productList)
    if (payload.product.id) {
      const product: IProduct = yield call(ProductApi.update, payload.product)
      const index = products.findIndex(({ id }) => id === product.id)
      products[index] = product
      yield put(changeProducts([...products]))
    } else {
      const product: IProduct = yield call(ProductApi.create, payload.product)
      yield put(changeProducts([...products, product]))
    }
    yield put(clearProductState())
    yield call(payload.navigate, pages.PRODUCT)
  } catch (error: any) {
    alert(error.message)
    console.error(error)
  } finally {
    yield put(changeSaveLoading(false))
  }
}

function* removeProduct({ payload }: any) {
  try {
    yield put(changeLoading(true))
    const products: IProduct[] = yield select((state: IState) => state.product.productList)
    yield call(ProductApi.remove, payload.id)
    const index = products.findIndex(({ id }) => id === payload.id)
    if (index !== -1) {
      products.splice(index, 1)
    }
    yield put(changeProducts([...products]))
  } catch (error: any) {
    alert(error.message)
    console.error(error)
  } finally {
    yield put(changeLoading(false))
  }
}

export default function* ProductSaga() {
  const getProductListSaga: unknown = yield takeEvery(PRODUCT_ACTIONS.ASYNC_GET_PRODUCTS, getProductList)

  const saveProductSaga: unknown = yield takeEvery(PRODUCT_ACTIONS.ASYNC_SAVE_PRODUCT, saveProduct)

  const getProductSaga: unknown = yield takeEvery(PRODUCT_ACTIONS.ASYNC_GET_PRODUCT, getProduct)

  const removeProductSaga: unknown = yield takeEvery(PRODUCT_ACTIONS.ASYNC_REMOVE_PRODUCT, removeProduct)

  yield all([getProductListSaga, saveProductSaga, getProductSaga, removeProductSaga])
}
