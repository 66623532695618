import { Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Icon } from 'semantic-ui-react';
import Input from '../../../components/Input/Input';
import Loading from '../../../components/Loading/Loading';
import MoneyDisplay from '../../../components/MoneyDisplay/MoneyDisplay';
import PageHeader from '../../../components/PageTitle/PageTitle';
import { IState } from '../../../config/store/MainReducer';
import { IClient } from '../../../models/client.model';
import { IOrder, IOrderProduct } from '../../../models/order.model';
import { IVehicle } from '../../../models/vehicle';
import { pages } from '../../../utils/constants/pages';
import { getClientsList } from '../../client/store/actions';
import { getProductList } from '../../product/store/actions';
import VehicleCategorySelect from '../../shared/component/VehicleCategorySelect';
import { clearOrderEdit, getOrder, saveOrder } from '../store/actions';
import {
  OrderProductItem,
  OrderProductItemMoney,
  OrderProductItemName,
} from './OrderEdit.style';

const OrderEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { loadingOrder, saveLoading, orderEdit } = useSelector(
    ({ order }: IState) => ({
      saveLoading: order.saveLoading,
      loadingOrder: order.loading,
      orderEdit: order.orderEdit,
    })
  );

  const [currentProducts, changeCurrentProducts] = useState<IOrderProduct[]>(
    orderEdit.products ?? []
  );

  useEffect(() => {
    if (id) {
      dispatch(getOrder(id));
    }
    return () => {
      dispatch(clearOrderEdit());
    };
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getProductList());
    dispatch(getClientsList());
  }, [dispatch]);

  const { clients } = useSelector(({ client }: IState) => ({
    clients: client.clients,
  }));

  const { productList } = useSelector(({ product }: IState) => ({
    productList: product.productList,
  }));

  function getClientVehicle(
    license_plate: string
  ): [IClient | undefined, IVehicle | undefined] {
    if (license_plate.length !== 7) return [undefined, undefined];

    const client = clients.find((client) => {
      return client.vehicles.some(
        (vehicle) => vehicle.license_plate === license_plate
      );
    });

    const vehicle = client?.vehicles.find(
      (vehicle) => vehicle.license_plate === license_plate
    );

    return [client, vehicle];
  }

  function totalPriceCurrentProducts(products: IOrderProduct[]) {
    return products.reduce((acc, curr) => {
      return acc + (curr.price ?? 0);
    }, 0);
  }

  return loadingOrder ? (
    <Loading />
  ) : (
    <>
      <PageHeader
        title={`${id ? 'Editar' : 'Entrada'} Veículo`}
        backRouter={pages.DASHBOARD}
      />
      <Formik
        enableReinitialize
        initialValues={
          {
            vehicle: orderEdit.vehicle,
          } as any
        }
        validate={(values) => {
          let errors: any = {};
          return errors;
        }}
        onSubmit={(order: IOrder) => {
          let orderToSave = id ? { ...orderEdit, ...order } : order;
          order.enteredAt = order.enteredAt ?? moment().format();
          console.log(orderToSave);
          dispatch(saveOrder(orderToSave as IOrder, navigate));
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, setValues }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <label>Placa</label>
              <Input
                maxLength="7"
                name="vehicle.license_plate"
                placeholder="BRA0S17"
                autoComplete="off"
                value={values.vehicle.license_plate}
                onChange={(event) => {
                  const license_plate: string =
                    event.target.value.toUpperCase();
                  const [client, vehicle] = getClientVehicle(license_plate);

                  setValues({
                    ...values,
                    consumerId: client?.id,
                    consumerName: client?.name,
                    consumerPhoneNumber: client?.phoneNumber,
                    vehicle: {
                      categoryId: '',
                      model: '',
                      ...vehicle,
                      license_plate,
                    },
                  });
                }}
                onBlur={handleBlur}
              />
            </Form.Field>
            {values.consumerId ? (
              <>
                <h5>Cliente encontrado</h5>
                <p>Nome: {values.consumerName}</p>
                <p>Telefone: {values.consumerPhoneNumber}</p>
              </>
            ) : (
              <>
                <h5>Cliente não cadastrado</h5>
              </>
            )}
            <Form.Field>
              <label>Modelo</label>
              <Input
                name="vehicle.model"
                autoComplete="off"
                disabled={!!values.consumerId}
                placeholder="Modelo"
                value={values.vehicle.model}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Field>
            <VehicleCategorySelect
              disabled={!!values.consumerId}
              value={values.vehicle.category}
              onSelect={(category) => {
                setValues({
                  ...values,
                  vehicle: {
                    ...values.vehicle,
                    categoryId: category.id,
                    category,
                  },
                });
              }}
            ></VehicleCategorySelect>
            {values.vehicle.category ? (
              <>
                <Form.Field>
                  <label>Adicionar Serviços</label>
                  {productList.map((product) => {
                    const currentCategoryId = values.vehicle.categoryId;
                    const productVehicle = product.vehicles.find(
                      (vehicle) => vehicle.categoryId === currentCategoryId
                    );
                    if (currentProducts.find((p) => p.name === product.name))
                      return <></>;
                    if (!productVehicle) return <></>;

                    return (
                      <OrderProductItem key={product.name}>
                        <OrderProductItemName>
                          {product.name}
                        </OrderProductItemName>
                        <OrderProductItemMoney>
                          <MoneyDisplay
                            value={productVehicle.price}
                          ></MoneyDisplay>
                        </OrderProductItemMoney>
                        <Button
                          size="mini"
                          color="green"
                          icon
                          onClick={() => {
                            const orderProduct: IOrderProduct = {
                              price: productVehicle.price,
                              name: product.name,
                              description: '',
                              performedById: '',
                            };
                            const newCurrentProducts = [
                              ...(values.products ?? []),
                              orderProduct,
                            ];
                            setValues({
                              ...values,
                              products: newCurrentProducts,
                              totalPrice:
                                totalPriceCurrentProducts(newCurrentProducts),
                            });
                            changeCurrentProducts(newCurrentProducts);
                          }}
                        >
                          <Icon name="plus" />
                        </Button>
                      </OrderProductItem>
                    );
                  })}
                </Form.Field>
                <Form.Field>
                  <label>Serviços</label>
                  {currentProducts.map((product) => (
                    <OrderProductItem key={product.name}>
                      <OrderProductItemName>
                        {product.name}{' '}
                      </OrderProductItemName>
                      <OrderProductItemMoney>
                        <MoneyDisplay value={product.price}></MoneyDisplay>
                      </OrderProductItemMoney>

                      <Button
                        size="mini"
                        color="red"
                        icon
                        onClick={() => {
                          currentProducts.splice(
                            currentProducts.findIndex(
                              (v) => v.name === product.name
                            ),
                            1
                          );
                          const newCurrentProducts = [...currentProducts];
                          setValues({
                            ...values,
                            products: newCurrentProducts,
                            totalPrice:
                              totalPriceCurrentProducts(newCurrentProducts),
                          });
                          changeCurrentProducts(newCurrentProducts);
                        }}
                      >
                        <Icon name="minus" />
                      </Button>
                    </OrderProductItem>
                  ))}
                  <OrderProductItem>
                    <OrderProductItemName>
                      <h5>Total</h5>{' '}
                    </OrderProductItemName>
                    <OrderProductItemMoney>
                      <MoneyDisplay value={values.totalPrice}></MoneyDisplay>
                    </OrderProductItemMoney>
                  </OrderProductItem>
                </Form.Field>
              </>
            ) : (
              <></>
            )}

            <Button
              primary
              type="submit"
              loading={saveLoading}
              disabled={
                currentProducts.length <= 0 ||
                values.vehicle.license_plate.length !== 7 ||
                !values.vehicle.model ||
                !values.vehicle.category
              }
            >
              Salvar
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default OrderEdit;
