export const pages = {
  LOGIN: '/login',
  NOT_FOUND: 'not-found',

  DASHBOARD: '/home/painel',

  HOME: '/home',
  EMPLOYEES: '/home/funcionarios',
  EMPLOYEE_CREATE: '/home/funcionarios/criar',
  EMPLOYEE_EDIT: (id: string = ':id') => `/home/funcionarios/editar/${id}`,

  EXPENSE: '/home/despesa',
  EXPENSE_CREATE: '/home/despesa/criar',
  EXPENSE_EDIT: (id: string = ':id') => `/home/despesa/editar/${id}`,

  PRODUCT: '/home/servicos',
  PRODUCT_CREATE: '/home/servicos/criar',
  PRODUCT_EDIT: (id: string = ':id') => `/home/servicos/editar/${id}`,

  CLIENTS: '/home/clientes',
  CLIENT_CREATE: '/home/clientes/criar',
  CLIENT_EDIT: (id: string = ':id') => `/home/clientes/editar/${id}`,

  ORDER: '/home/pedidos',
  ORDER_CREATE: '/home/pedidos/criar',
  ORDER_EDIT: (id: string = ':id') => `/home/pedidos/editar/${id}`,
};
