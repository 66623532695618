import { all, call, put, takeEvery } from 'redux-saga/effects';
import { IDashboardStatistic } from '../../../models/dashboard.model';
import DashboardApi from '../../../services/dashboard/Dashboard';
import { changeDashboardStatistic, changeLoading } from './actions';
import { DASHBOARD_ACTIONS } from './reducer';

function* getDashboardStatistic({ payload }: any) {
  try {
    yield put(changeLoading(true));
    const { startDate, endDate } = payload;
    const dashboardStatistic: IDashboardStatistic = yield call(
      DashboardApi.get,
      startDate,
      endDate
    );
    yield put(changeDashboardStatistic(dashboardStatistic));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(changeLoading(false));
  }
}

export default function* DashboardSaga() {
  const getDashboardStatisticSaga: unknown = yield takeEvery(
    DASHBOARD_ACTIONS.ASYNC_GET_DASHBOARD,
    getDashboardStatistic
  );

  yield all([getDashboardStatisticSaga]);
}
