import { EmptyContentContainer, EmptyContentText } from './EmptyContent.style';

interface IProps {
  message: string;
}
const EmptyContent = ({ message }: IProps) => {
  return (
    <EmptyContentContainer>
      <EmptyContentText>{message}</EmptyContentText>
    </EmptyContentContainer>
  );
};

export default EmptyContent;
