import React from 'react';
import { theme } from '../../styles/theme';

interface IProps {
  width: number;
  height: number;
  color?: string;
}

function IconService({
  width,
  height,
  color = theme.color.secondary,
}: IProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.7661 4.65565L25 0.045166L22.2339 4.65484C21.9331 5.15646 21.7742 5.72984 21.7742 6.31533V6.45162C21.7742 8.23065 23.221 9.67742 25 9.67742C26.779 9.67742 28.2258 8.23065 28.2258 6.45162V6.31533C28.2258 5.72984 28.0669 5.15565 27.7661 4.65565ZM26.6129 6.45162C26.6129 7.34113 25.8895 8.06452 25 8.06452C24.1105 8.06452 23.3871 7.34113 23.3871 6.45162V6.31533C23.3871 6.02258 23.4669 5.73629 23.6169 5.48549L25 3.18065L26.3831 5.48629C26.5331 5.73629 26.6129 6.02258 26.6129 6.31533V6.45162Z"
        fill={color}
      />
      <path
        d="M9.21774 21.5911L6.45161 16.9806L3.68548 21.5903C3.38468 22.0919 3.22581 22.6653 3.22581 23.2508V23.3871C3.22581 25.1661 4.67258 26.6129 6.45161 26.6129C8.23065 26.6129 9.67742 25.1661 9.67742 23.3871V23.2508C9.67742 22.6653 9.51855 22.0911 9.21774 21.5911ZM8.06452 23.3871C8.06452 24.2766 7.34113 25 6.45161 25C5.5621 25 4.83871 24.2766 4.83871 23.3871V23.2508C4.83871 22.9581 4.91855 22.6718 5.06855 22.421L6.45161 20.1161L7.83468 22.4218C7.98468 22.6718 8.06452 22.9581 8.06452 23.2508V23.3871Z"
        fill={color}
      />
      <path
        d="M46.3145 13.5266L43.5484 8.91614L40.7823 13.5258C40.4815 14.0274 40.3226 14.6008 40.3226 15.1863V15.3226C40.3226 17.1016 41.7694 18.5484 43.5484 18.5484C45.3274 18.5484 46.7742 17.1016 46.7742 15.3226V15.1863C46.7742 14.6008 46.6153 14.0266 46.3145 13.5266ZM45.1613 15.3226C45.1613 16.2121 44.4379 16.9355 43.5484 16.9355C42.6589 16.9355 41.9355 16.2121 41.9355 15.3226V15.1863C41.9355 14.8936 42.0153 14.6073 42.1653 14.3565L43.5484 12.0516L44.9315 14.3573C45.0815 14.6073 45.1613 14.8936 45.1613 15.1863V15.3226Z"
        fill={color}
      />
      <path
        d="M49.7782 30.7919L46.3847 23.4411C45.4653 21.4484 43.4532 20.1613 41.2589 20.1613H22.8331C20.8944 20.1613 19.0718 20.9161 17.7016 22.2871L11.7629 28.2258H7.25806C3.25565 28.2258 0 31.4823 0 35.4839V41.129C0 42.4629 1.08548 43.5484 2.41935 43.5484H4.07984C4.48226 47.1718 7.56048 50 11.2903 50C15.0202 50 18.0984 47.1718 18.5008 43.5484H31.4992C31.9016 47.1718 34.9798 50 38.7097 50C42.4395 50 45.5177 47.1718 45.9202 43.5484H47.5806C48.9145 43.5484 50 42.4629 50 41.129V31.8056C50 31.4573 49.9226 31.1073 49.7782 30.7919ZM11.2903 48.3871C8.17823 48.3871 5.64516 45.8548 5.64516 42.7419C5.64516 39.629 8.17823 37.0968 11.2903 37.0968C14.4024 37.0968 16.9355 39.629 16.9355 42.7419C16.9355 45.8548 14.4024 48.3871 11.2903 48.3871ZM38.7097 48.3871C35.5976 48.3871 33.0645 45.8548 33.0645 42.7419C33.0645 39.629 35.5976 37.0968 38.7097 37.0968C41.8218 37.0968 44.3548 39.629 44.3548 42.7419C44.3548 45.8548 41.8218 48.3871 38.7097 48.3871ZM48.3871 33.0645H46.7742V34.6774H48.3871V36.2903H46.7742V37.9032H48.3871V41.129C48.3871 41.5734 48.0258 41.9355 47.5806 41.9355H45.9202C45.5177 38.3121 42.4395 35.4839 38.7097 35.4839C34.9798 35.4839 31.9016 38.3121 31.4992 41.9355H29.8387V32.2581H28.2258V41.9355H18.5008C18.0984 38.3121 15.0202 35.4839 11.2903 35.4839C7.56048 35.4839 4.48226 38.3121 4.07984 41.9355H2.41935C1.97419 41.9355 1.6129 41.5734 1.6129 41.129V35.4839C1.6129 32.371 4.14597 29.8387 7.25806 29.8387H12.4306L18.8419 23.4274C19.9081 22.3613 21.325 21.7742 22.8331 21.7742H41.2589C42.8258 21.7742 44.2629 22.6935 44.9194 24.1169L48.3129 31.4677C48.3621 31.5726 48.3871 31.6895 48.3871 31.8056V33.0645Z"
        fill={color}
      />
      <path
        d="M3.22581 35.4839H4.83871C4.83871 34.15 5.92419 33.0645 7.25807 33.0645V31.4516C5.03468 31.4516 3.22581 33.2605 3.22581 35.4839Z"
        fill={color}
      />
      <path d="M25 32.2581H21.7742V33.871H25V32.2581Z" fill={color} />
      <path
        d="M11.2903 39.5161C9.51129 39.5161 8.06451 40.9629 8.06451 42.7419C8.06451 44.521 9.51129 45.9677 11.2903 45.9677C13.0694 45.9677 14.5161 44.521 14.5161 42.7419C14.5161 40.9629 13.0694 39.5161 11.2903 39.5161ZM11.2903 44.3548C10.4008 44.3548 9.67742 43.6315 9.67742 42.7419C9.67742 41.8524 10.4008 41.129 11.2903 41.129C12.1798 41.129 12.9032 41.8524 12.9032 42.7419C12.9032 43.6315 12.1798 44.3548 11.2903 44.3548Z"
        fill={color}
      />
      <path
        d="M38.7097 39.5161C36.9306 39.5161 35.4839 40.9629 35.4839 42.7419C35.4839 44.521 36.9306 45.9677 38.7097 45.9677C40.4887 45.9677 41.9355 44.521 41.9355 42.7419C41.9355 40.9629 40.4887 39.5161 38.7097 39.5161ZM38.7097 44.3548C37.8202 44.3548 37.0968 43.6315 37.0968 42.7419C37.0968 41.8524 37.8202 41.129 38.7097 41.129C39.5992 41.129 40.3226 41.8524 40.3226 42.7419C40.3226 43.6315 39.5992 44.3548 38.7097 44.3548Z"
        fill={color}
      />
      <path
        d="M43.4548 24.7936C43.0613 23.9395 42.2 23.3871 41.2589 23.3871H37.9032V25H41.2589C41.5726 25 41.8597 25.1839 41.9903 25.4686L43.6355 29.0323H17.7984L21.1226 25.7081C21.5726 25.2581 22.196 25 22.8331 25H36.2903V23.3871H22.8331C21.7718 23.3871 20.7331 23.8177 19.9823 24.5677L13.9048 30.6452H46.1573L43.4548 24.7936Z"
        fill={color}
      />
      <path d="M1.6129 0H0V1.6129H1.6129V0Z" fill={color} />
      <path
        d="M7.25807 9.67743H5.64516V11.2903H7.25807V9.67743Z"
        fill={color}
      />
      <path
        d="M16.9355 4.03226H15.3226V5.64516H16.9355V4.03226Z"
        fill={color}
      />
      <path
        d="M13.7097 20.9677H12.0968V22.5806H13.7097V20.9677Z"
        fill={color}
      />
      <path
        d="M25.8065 13.7097H24.1936V15.3226H25.8065V13.7097Z"
        fill={color}
      />
      <path
        d="M36.2903 10.4839H34.6774V12.0968H36.2903V10.4839Z"
        fill={color}
      />
      <path d="M39.5161 0H37.9032V1.6129H39.5161V0Z" fill={color} />
      <path d="M50 5.64516H48.3871V7.25806H50V5.64516Z" fill={color} />
    </svg>
  );
}

export default IconService;
