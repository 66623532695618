import {
    ILoginCredentials,
    IUserLogged,
} from '../../pages/login/store/reducer';
import { URLS } from '../../utils/constants/urls';
import { Post } from '../api/Api';
import Storage, { StorageKeys } from '../storage/Storage';

const AuthApi = {
    login: async (
        credentials: ILoginCredentials | { refreshToken: string }
    ): Promise<{
        accessToken: string | null;
        refreshToken: string | null;
        user: IUserLogged;
    }> => {
        const response = await Post(URLS.LOGIN, credentials);
        if (response.ok) {
            const user = await response.json();
            Storage.save(StorageKeys.USER_LOGGED, user);
            return user;
        } else {
            return Promise.reject(await response.json());
        }
    }
};

export default AuthApi;