import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { IState } from '../../../config/store/MainReducer';
import { IOrder } from '../../../models/order.model';
import OrderApi from '../../../services/order/Order';
import { pages } from '../../../utils/constants/pages';
import {
  changeLoading,
  changeOrderEdit,
  changeOrders,
  changeSaveLoading,
  clearOrderEdit,
} from './actions';
import { ORDER_ACTIONS } from './reducer';

function* getOrderList() {
  try {
    yield put(changeLoading(true));
    const orders: IOrder[] = yield call(OrderApi.list);
    yield put(changeOrders(orders));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(changeLoading(false));
  }
}

function* getOrder({ payload }: any) {
  try {
    yield put(changeLoading(true));
    const order: IOrder = yield call(OrderApi.get, payload.id);
    yield put(changeOrderEdit(order));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(changeLoading(false));
  }
}

function* saveOrder({ payload }: any) {
  try {
    yield put(changeSaveLoading(true));
    const orders: IOrder[] = yield select(
      (state: IState) => state.order.orderList
    );
    if (payload.order.id) {
      const order: IOrder = yield call(OrderApi.update, payload.order);
      const index = orders.findIndex(({ id }) => id === order.id);
      orders[index] = order;
      yield put(changeOrders([...orders]));
    } else {
      const order: IOrder = yield call(OrderApi.create, payload.order);
      yield put(changeOrders([...orders, order]));
    }
    yield put(clearOrderEdit());
    if (payload.navigate) {
      yield call(payload.navigate, pages.ORDER);
    }
  } catch (error: any) {
    alert(error.message);
    console.error(error);
  } finally {
    yield put(changeSaveLoading(false));
  }
}

function* removeOrder({ payload }: any) {
  try {
    yield put(changeLoading(true));
    const orders: IOrder[] = yield select(
      (state: IState) => state.order.orderList
    );
    yield call(OrderApi.remove, payload.id);
    const index = orders.findIndex(({ id }) => id === payload.id);
    if (index !== -1) {
      orders.splice(index, 1);
    }
    yield put(changeOrders([...orders]));
  } catch (error: any) {
    alert(error.message);
    console.error(error);
  } finally {
    yield put(changeLoading(false));
  }
}

export default function* OrderSaga() {
  const getOrderListSaga: unknown = yield takeEvery(
    ORDER_ACTIONS.ASYNC_GET_ORDERS,
    getOrderList
  );

  const saveOrderSaga: unknown = yield takeEvery(
    ORDER_ACTIONS.ASYNC_SAVE_ORDER,
    saveOrder
  );

  const getOrderSaga: unknown = yield takeEvery(
    ORDER_ACTIONS.ASYNC_GET_ORDER,
    getOrder
  );

  const removeOrderSaga: unknown = yield takeEvery(
    ORDER_ACTIONS.ASYNC_REMOVE_ORDER,
    removeOrder
  );

  yield all([getOrderListSaga, saveOrderSaga, getOrderSaga, removeOrderSaga]);
}
