import {useEffect, useMemo, useState} from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Select } from 'semantic-ui-react';
import ModalWrapper from '../../../../components/Modal/Modal';
import { IState } from '../../../../config/store/MainReducer';
interface IProps {
  TriggerComponent: any;
  onChoose(employeeId: string): void;
}
const ChooseEmployeeToService = ({
   TriggerComponent,
   onChoose,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [employeeIdSelected, setEmployeeIdSelected] = useState('');
  const employees = useSelector((state: IState) => state.employee.employees);
  useEffect(() => {
      return () => {
          setEmployeeIdSelected('');
      }
  },[])
  const options = useMemo(
    () =>
      employees.map((employee) => ({
        key: employee.id,
        text: employee.name,
        value: employee.id,
      })),
    [employees]
  );
  function handleCancelModal() {
    setIsOpen(false);
  }

  function handleChooseEmployee() {
    // Make Request to Pick the Employee
    setIsOpen(false);
    onChoose(employeeIdSelected);
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      title="Escolha o funcionário"
      changeOpen={handleCancelModal}
      onCancel={() => setIsOpen(false)}
      trigger={
        <div onClick={() => setIsOpen(true)}>
          <TriggerComponent />
        </div>
      }
      actions={
        <>
          <Button basic color="red" onClick={handleCancelModal}>
            Cancelar
          </Button>
          <Button content="Salvar" onClick={handleChooseEmployee} primary />
        </>
      }
    >
      <Form>
        <Form.Field>
          <Select
            value={employeeIdSelected}
            placeholder="Selecione a categoria"
            options={options}
            onChange={(_, { value }) => setEmployeeIdSelected(value as string)}
          />
        </Form.Field>
      </Form>
    </ModalWrapper>
  );
};
export default ChooseEmployeeToService;
