import React, { useEffect } from 'react';
import { Modal } from 'semantic-ui-react';

interface IProps {
  trigger: any;
  children: any;
  title: string;
  isOpen?: boolean;
  changeOpen(value: boolean): void;
  onCancel(): void;
  actions: any;
}

function ModalWrapper({
  children,
  title,
  trigger,
  isOpen,
  changeOpen,
  onCancel,
  actions,
}: IProps) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(isOpen as boolean);
  }, [isOpen]);

  function handleCancelModal() {
    changeOpen(false);
    onCancel();
    setOpen(false);
  }

  return (
    <Modal
      style={{ zIndex: 1010 }}
      onClose={() => handleCancelModal()}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>{actions}</Modal.Actions>
    </Modal>
  );
}

export default ModalWrapper;
